import { ThemeContrastStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getCorrespondingLegacyTheme, getThemeInfo } from '@/themes';
import { Themes } from '@/types';
import { cls } from '@/utils';
import styles from './ContainerFluid.module.scss';

interface Props {
	children: React.ReactNode;
	theme?: Themes['theme'];
	className?: string;
	marginBottom?: 'none' | 'xl' | '3xl' | '6xl' | 'custom';
	noBackgroundColor?: boolean;
	backgroundPattern?: boolean;
	applyChildTheme?: boolean;
	contrast?: ThemeContrastStoryblok['theme'];
}

/**
 * ContainerFluid is a responsive full width container that will fill the entire width of the viewport.
 * Use Section component inside to wrap the content. Use BackgroundImage inside ContainerFluid to add a full
 * width background image.
 */
export const ContainerFluid: React.FC<Props> = ({
	children,
	theme = 'white',
	className,
	marginBottom = 'none',
	noBackgroundColor,
	backgroundPattern = false,
	applyChildTheme = false,
	contrast,
}) => {
	const { hasSubMenu } = useSubMenu();
	const themeInfo = getThemeInfo(getCorrespondingLegacyTheme(theme));
	const marginBottomSubMenu = theme && theme !== 'white' && theme !== 'submenu' ? '3xl' : 'none';

	return (
		<ThemeProvider theme={themeInfo.name} contrast={contrast} applyChildTheme={applyChildTheme}>
			<div
				className={cls(
					styles.container,
					marginBottom !== 'custom' && styles[`margin-bottom--${hasSubMenu ? marginBottomSubMenu : marginBottom}`],
					hasSubMenu && styles.borderRadius,
					className,
					noBackgroundColor ? styles.noBackgroundColor : themeInfo.styles.bgColor,
					backgroundPattern && themeInfo.styles?.backgroundPattern,
				)}
			>
				{children}
			</div>
		</ThemeProvider>
	);
};
