import { LinkButton } from '@/bloks/Button';
import { t } from 'ttag';
import styles from './ShortCuts.module.scss';

interface Props {
	className?: string;
}
export const ShortCuts: React.FC<Props> = () => {
	const quickChoices: Record<string, any>[] = [
		{
			title: t`Se alla kategorier`,
			icon: 'tags',
			href: '/integrationer/kategorier',
		},
		{
			title: t`Se alla leverantörer`,
			icon: 'handshake',
			href: '/integrationer/leverantorer',
		},
	];

	return (
		<div className={styles.shortCuts}>
			{quickChoices.map((cat) => {
				return (
					<LinkButton variant="text" key={cat.title} href={cat.href}>
						{cat.title}
					</LinkButton>
				);
			})}
		</div>
	);
};
