import { ContainerFluid, Padding, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import DynamicComponent from '@/bloks/DynamicComponent';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { SectionCardStoryblok, ThemeFullStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ThemeProvider } from '@/contexts/theme';
import { getCorrespondingLegacyTheme } from '@/themes';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import React, { ReactNode } from 'react';
import styles from './SectionCard.module.scss';

interface BlokProps {
	blok: SectionCardStoryblok;
	meta?: {
		containerFoldoutButton?: JSX.Element;
		zone?: number;
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	const theme = (getCorrespondingLegacyTheme(blok?.theme?.[0]?.theme) ?? 'white') as ThemeFullStoryblok['theme'];
	const backgroundPattern = blok.backgroundPattern ?? false;
	const padding = backgroundPattern;
	return {
		header: blok.header?.[0] && HeaderCommon.blokProps({ blok: blok.header?.[0] }),
		cardGroup: blok.cardGroup?.map((cardGroup) => (
			<DynamicComponent key={cardGroup._uid} blok={cardGroup} meta={{ ...meta, padding, theme }} />
		)),
		theme,
		component: blok.cardGroup?.[0]?.component ?? '',
		backgroundPattern,
		_editable: blok._editable,
		containerFoldoutButton: meta?.containerFoldoutButton,
		zone: meta?.zone,
	};
};

interface SectionPadding {
	base: Padding;
	xs?: Padding;
	sm?: Padding;
	md?: Padding;
	lg?: Padding;
	xl?: Padding;
	xxl?: Padding;
}
interface Props {
	theme: ThemeFullStoryblok['theme'];
	header?: HeaderCommonProps;
	cardGroup?: ReactNode[];
	component: string;
	backgroundPattern?: boolean;
	_editable?: string;
	containerFoldoutButton?: JSX.Element;
	zone?: number;
}

export const SectionCard: Blok<Props, BlokProps> = ({
	header,
	cardGroup,
	theme,
	component,
	backgroundPattern = false,
	_editable,
	containerFoldoutButton,
	zone,
}) => {
	const isCardLargeInverted = component === 'CardLargeInvertedGroup';
	const isCardLarge = isCardLargeInverted || component === 'CardLargeGroup';
	const removePaddingX = isCardLarge || component === 'CardMediumUspGroup';

	const removePaddingXmd = component === 'CardMediumGroup';
	const applyChildTheme = backgroundPattern && component !== 'CardLargeGraphicGroup';

	const paddingY = (): SectionPadding | undefined => {
		if (component === 'CardLargeInvertedGroup') {
			return { base: 'none' };
		}

		if (component === 'CardLargeGroup') {
			return { base: 'none', lg: '5xl' };
		}
	};

	const paddingX = (): SectionPadding | undefined => {
		if (removePaddingX) {
			return { base: 'none' };
		}

		if (removePaddingXmd) {
			return { base: 'none', md: '3xl', lg: '5xl' };
		}

		return undefined;
	};

	const contentName = `${zone}|SectionCard`;
	const contentPiece = header && header?.titlePart2 ? `${header?.titlePart1} ${header.titlePart2}` : header?.titlePart1;

	const content = (
		<ContainerFluid
			theme={theme}
			backgroundPattern={backgroundPattern}
			applyChildTheme={applyChildTheme}
			noBackgroundColor={isCardLargeInverted}
			contrast={isCardLarge || isCardLargeInverted ? 'lightText' : undefined}
		>
			<Section
				paddingX={paddingX()}
				paddingY={paddingY()}
				{...editableBlok({ _editable })}
				maxWidth={isCardLargeInverted ? '2561px' : undefined}
			>
				{header && (
					<ThemeProvider theme={theme}>
						<HeaderCommon {...header} marginBottom="3xl" className={cls(removePaddingXmd && styles.padding)} />
					</ThemeProvider>
				)}
				{cardGroup}
				{containerFoldoutButton && (
					<ThemeProvider theme={theme}>
						<div
							className={cls(
								styles.containerFoldoutButton,
								isCardLarge && styles.foldoutButtonCardLarge,
								isCardLargeInverted && styles.foldoutButtonCardLargeInverted,
							)}
						>
							{containerFoldoutButton}
						</div>
					</ThemeProvider>
				)}
			</Section>
		</ContainerFluid>
	);

	const cardLargeGroups = ['CardLargeGroup', 'CardLargeInvertedGroup', 'CardLargeGraphicGroup'];
	const hasOwnImpressionProvider = cardLargeGroups.includes(component);
	return hasOwnImpressionProvider ? (
		content
	) : (
		<ImpressionTrackingProvider value={{ contentName, contentPiece }}>{content}</ImpressionTrackingProvider>
	);
};

SectionCard.blokProps = blokProps;
