import { ContainerFluid, Grid, PageLayout, Section, TextField } from '@/atoms';
import { CardEducation } from '@/bloks/Page/NavigationPageEducation/CardEducation/CardEducation';
import { toCardEducation } from '@/bloks/Page/NavigationPageEducation/CardEducation/EduAdminCardEducation';
import { FilterContainer, QueryParams } from '@/bloks/Page/NavigationPageEducation/EducationFilter/FilterContainer';
import { Item } from '@/bloks/Page/NavigationPageEducation/EducationFilter/ItemFilter';
import { SelectedFilters } from '@/bloks/Page/NavigationPageEducation/EducationFilter/SelectedFilters';
import {
	CardEducationStoryblok,
	CardFiveGroupStoryblok,
	NavigationPageEducationStoryblok,
	SectionTextLinkStoryblok,
	SubMenuStoryblok,
} from '@/components';
import { useCourses } from '@/contexts/eduadmin';
import { ThemeProvider } from '@/contexts/theme';
import { ISbStoryData } from '@/types/storyblok';
import { editableBlok } from '@/utils/storyblok';
import React, { useState } from 'react';
import DynamicComponent from '../../DynamicComponent';
import { SectionTextLink } from '../../Section';
import styles from './NavigationPageEducation.module.scss';

interface Props {
	blok: NavigationPageEducationStoryblok;
	meta: Record<string, any>;
}

export const NavigationPageEducation: React.FC<Props> = ({ blok, meta }) => {
	const { courses, subjects, educationTypes, targetGroups } = useCourses();
	const globalTextAndLinkSection = blok?.globalTextAndLinkSection as unknown as SectionTextLinkStoryblok;
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const educationCards = courses?.value?.map((course) => toCardEducation(course)) || [];
	blok?.extra?.map((card) => {
		if (card.alwaysVisible !== true) {
			educationCards.push(card as CardEducationStoryblok);
		}
	});
	const alwaysVisibleCards = blok?.extra?.filter((card) => card.alwaysVisible === true) || [];
	const [filteredCards, setFilteredCards] = useState<CardEducationStoryblok[]>(educationCards);
	const [selectedFilters, setSelectedFilters] = useState<Record<string, Item[]>>({
		[QueryParams.TARGET_GROUP]: [],
		[QueryParams.SUBJECT]: [],
		[QueryParams.EDUCATION_TYPE]: [],
		[QueryParams.PRICE]: [],
	});

	return (
		<PageLayout subMenu={subMenu} exits={contact} meta={meta}>
			<div {...editableBlok(blok)}>
				{blok.header && blok.header?.map((blok) => <DynamicComponent key={blok._uid} blok={blok} />)}
				<ContainerFluid>
					<Section className={styles.section} paddingX={{ base: 'xl' }} paddingY={{ base: '4xl' }}>
						<FilterContainer
							educationCards={educationCards}
							setFilteredCards={setFilteredCards}
							subjects={subjects}
							educationTypes={educationTypes}
							targetGroups={targetGroups}
							selectedFilters={selectedFilters}
							setSelectedFilters={setSelectedFilters}
						/>
						<Grid columns={{ base: 1, lg: 1, xl: 2, xxl: 3 }} colGap="xl" rowGap="xl">
							<Grid.Item colSpan={{ base: 1, lg: 1, xl: 2, xxl: 3 }}>
								<SelectedFilters selectedFilters={selectedFilters} />
							</Grid.Item>
							{filteredCards?.map((card) => {
								return (
									<Grid.Item key={card._uid} className={styles.cardAnimation}>
										<ThemeProvider key={card._uid} theme="white">
											<CardEducation blok={card} />
										</ThemeProvider>
									</Grid.Item>
								);
							})}
							{alwaysVisibleCards?.map((card) => {
								return (
									<Grid.Item key={card._uid} className={styles.cardAnimation}>
										<ThemeProvider key={card._uid} theme="white">
											<CardEducation blok={card} />
										</ThemeProvider>
									</Grid.Item>
								);
							})}
						</Grid>
					</Section>
				</ContainerFluid>
				{blok?.globalTextAndLinkSection && <SectionTextLink blok={globalTextAndLinkSection} />}
			</div>
		</PageLayout>
	);
};
