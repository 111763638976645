import { Icon } from '@/atoms';
import { Accountant } from '@/contexts/accountants/types';
import { cls } from '@/utils';
import React from 'react';
import { t } from 'ttag';
import styles from './ContactInfo.module.scss';

interface ContactInfoProps {
	accountant: Accountant;
	show: boolean;
}

const urlWithProtocol = (url?: string) => {
	if (!url) {
		return null;
	}
	return url.indexOf('http://') == 0 || url.indexOf('https://') == 0
		? url.toLowerCase()
		: `https://${url.toLowerCase()}`;
};

export const ContactInfo: React.FC<ContactInfoProps> = ({ accountant, show }) => {
	const id = `${accountant.id}_${accountant.companyName}_${accountant.city}`;
	const addressRow = accountant.address ? `${accountant.address}, ${accountant.city}` : accountant.city;
	const homepageUrl = urlWithProtocol(accountant.homepage);
	const homepage = homepageUrl && homepageUrl.replace(/^(http|https):\/\/www\./, '');

	const mapsUrl = () => {
		const address = accountant.address ? `${accountant.address}, ${accountant.city}` : `${accountant.city}`;
		const searchParams = new URLSearchParams();
		searchParams.set('query', `${accountant.companyName}, ${address}`);
		searchParams.set('api', '1');
		return `https://www.google.se/maps/search/?${searchParams}`;
	};

	return (
		show && (
			<div className={cls(styles.contactContainer)}>
				<ul className={styles.contact}>
					<li className={styles.listItem}>
						<span className={styles.iconContainer}>
							<Icon name="location-dot" size="1x" />
						</span>
						<span className={styles.text}>
							<div className={styles.addressRow}>
								<a href={mapsUrl()} title={t`Se på karta`} rel="noopener noreferrer" target="_blank" id={`${id}_map`}>
									{addressRow}
									<Icon className={cls(styles.icon, styles.mapIcon)} name="external-link" size="1x" />
								</a>
							</div>
						</span>
					</li>
					<li className={styles.listItem}>
						<span className={styles.iconContainer}>
							<Icon name="phone" size="1x" />
						</span>
						<span>
							{accountant.phone ? (
								<a href={`tel:${accountant.phone}`} id={`${id}_phone`}>
									{accountant.phone}
								</a>
							) : (
								'-'
							)}
						</span>
					</li>
					<li className={styles.listItem}>
						<span className={styles.iconContainer}>
							<Icon name="envelope" size="1x" />
						</span>
						<span className={styles.text}>
							{accountant.email ? (
								<a
									href={`mailto:${accountant.email}`}
									title={accountant.email}
									rel="noopener noreferrer"
									target="_blank"
									id={`${id}_email`}
								>
									{accountant.email}
								</a>
							) : (
								'-'
							)}
						</span>
					</li>
					<li className={styles.listItem}>
						<span className={styles.iconContainer}>
							<Icon name="display" size="1x" />
						</span>
						<span className={styles.text}>
							{homepageUrl ? (
								<a
									href={homepageUrl}
									title={homepageUrl}
									rel="noopener noreferrer"
									target="_blank"
									id={`${id}_homepage`}
								>
									{homepage}
								</a>
							) : (
								'-'
							)}
						</span>
					</li>
				</ul>
			</div>
		)
	);
};
