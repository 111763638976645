import { Icon } from '@/atoms';
import React from 'react';
import { t } from 'ttag';
import styles from './SearchField.module.scss';

interface Props {
	value: string;
	setValue: (value: string) => void;
}

export const SearchField: React.FC<Props> = ({ value, setValue }) => {
	return (
		<div className={styles.searchField}>
			<input
				className={styles.inputField}
				placeholder={t`Sök`}
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>
			{value && value.length > 0 ? (
				<div onClick={() => setValue('')} className={styles.clearIcon}>
					<Icon name="fa-xmark" />
				</div>
			) : (
				<Icon name="fa-search" />
			)}
		</div>
	);
};
