import { BrandLine, ContainerFluid, Heading, Section, Tags } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { AssetStoryblok, HeaderArticleStoryblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { t } from 'ttag';
import styles from './HeaderArticle.module.scss';
import { SocialMedia } from './SocialMedia';

interface BlokProps {
	blok: HeaderArticleStoryblok;
	meta: {
		first_published_at?: string;
		published_at?: string;
		tags?: string[];
		full_slug: string;
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	h1Part1: blok.h1Part1,
	image: blok.image,
	email: blok.email,
	first_published_at: meta?.first_published_at,
	published_at: meta?.published_at,
	tags: meta.tags,
	full_slug: meta.full_slug,
	_editable: blok._editable,
});

interface Props {
	h1Part1: string;
	image?: AssetStoryblok;
	email?: string;
	first_published_at?: string;
	published_at?: string;
	tags?: string[];
	full_slug?: string;
	_editable?: string;
}

/**
 * HeaderArticle component - used in BlogPost, CompanyGuidePost, NewsPost and EducationPage
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=91%3A3583
 */
export const HeaderArticle: Blok<Props, BlokProps> = ({
	h1Part1,
	image,
	email,
	first_published_at,
	published_at,
	tags,
	full_slug,
	_editable,
}) => {
	const imageFooterItems = [email, image?.copyright, image?.alt, image?.name];
	const { hasSubMenuV1 } = useSubMenu();

	return (
		<ContainerFluid theme="white">
			<Section
				as="header"
				{...editableBlok({ _editable })}
				padding={{ base: 'none' }}
				maxWidth="925px"
				className={cls(hasSubMenuV1 && styles.marginTop)}
			>
				<BrandLine marginBottom="xl" />

				<div className={styles.tagsAndSocialMedia}>
					{tags && <Tags tags={tags} published={first_published_at} modified={published_at} />}

					{full_slug && h1Part1 && <SocialMedia href={full_slug} title={h1Part1} />}
				</div>

				<Heading as="h1" size="h2" title={h1Part1} marginBottom="xl" exo2 />

				{image && (
					<div className={styles.imageContainer}>
						{image?.filename && (
							<div className={styles.image}>
								<ImageAsset absolute priority asset={image} objectFit="cover" fill objectPosition="center" />
							</div>
						)}

						{imageFooterItems.length && (
							<div className={styles.imageFooter}>
								<div>{image?.name && `${t`Av`}: ${image?.name}`}</div>
								<div>{image?.copyright && `${t`Foto`}: ${image?.copyright}`}</div>

								{email && (
									<div className={styles.emailLinkContainer}>
										<a href={`mailto:${email}`} rel="noopener noreferrer">
											{email}
										</a>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</Section>
		</ContainerFluid>
	);
};

HeaderArticle.blokProps = blokProps;
