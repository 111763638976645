import { Button, Icon, IconButton, LinkAnchor, Logo, MenuIcon } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { getBannerIcon, getBannerTheme } from '@/bloks/Global/GlobalInfoBannerV2';
import { GlobalMenuV3Storyblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSearchV2 } from '@/contexts/search/AddSearchProviderV2';
import { ThemeProvider } from '@/contexts/theme';
import { useKeyPress } from '@/hooks/useKeyPress';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { cls } from '@/utils';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { t } from 'ttag';
import styles from './GlobalMenuV3.module.scss';
import { InternalVersionTag } from './components/InternalVersionTag';
import { MenuContentDesktop } from './components/MenuContentDesktop/MenuContentDesktop';
import { MenuContentMobile } from './components/MenuContentMobile/MenuContentMobile';

/**
 * For displaying currently deployed version below the Fortnox logo.
 * Only enabled for internal deployment.
 */
const showInternalVersionTag = !!process.env.NEXT_PUBLIC_PREVIEW_ENABLED;

interface BlokProps {
	blok: GlobalMenuV3Storyblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	topLinkButtons: blok.topLinkButtons?.map((btn) => LinkButton.blokProps({ blok: btn })),
	contactLinkButton: blok.contactLinkButton?.[0] && LinkButton.blokProps({ blok: blok.contactLinkButton[0] }),
	linkGroups: blok.linkGroups,
});

interface Props {
	topLinkButtons?: LinkButtonProps[];
	contactLinkButton?: LinkButtonProps;
	linkGroups?: GlobalMenuV3Storyblok['linkGroups'];
	scrollbarWidth?: null | number;
}

export const GlobalMenuV3: Blok<Props, BlokProps> = ({
	topLinkButtons,
	contactLinkButton,
	linkGroups,
	scrollbarWidth,
}) => {
	const { isMenuOpen, setIsMenuOpen, hasInfoBanner, infoBannerSeverity, toggleInfoBanner } = useGlobalMenu();
	const { isSearchOpen, setSearchOpen, setAnimation } = useSearchV2();
	const [hasScrolled, setHasScrolled] = useState(false);
	const menuRef = useRef<HTMLDivElement | null>(null);
	const pathname = usePathname();
	const theme = 'menuV3';

	useOnClickOutside(menuRef, () => setIsMenuOpen(false));

	useKeyPress('Escape', undefined, () => {
		setIsMenuOpen(false);
	});

	const handleOpenMenu = () => {
		if (isMenuOpen) {
			if (isSearchOpen) {
				setSearchOpen(false);
				setAnimation(null);
			}
		}

		setIsMenuOpen(!isMenuOpen);
	};

	const handleOpenSearch = () => {
		if (isMenuOpen) {
			setAnimation((prevAnimation) => (isSearchOpen && prevAnimation === 'slideIn' ? 'slideOut' : 'slideIn'));
		} else {
			setAnimation(null);
			setIsMenuOpen(true);
		}

		setSearchOpen(!isSearchOpen);
	};

	useEffect(() => {
		setIsMenuOpen(false);
	}, [pathname, setIsMenuOpen]);

	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	const onScroll = () => {
		if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
			setHasScrolled(true);
		} else {
			setHasScrolled(false);
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<RemoveScroll enabled={isMenuOpen} removeScrollBar={scrollbarWidth && scrollbarWidth > 0 ? false : true}>
				<FocusLock disabled={!isMenuOpen}>
					<header
						className={cls(
							styles.container,
							!hasScrolled || isMenuOpen ? styles.opaqueBgColor : styles.transparentBgColor,
							isMenuOpen && styles.isMenuOpen,
						)}
						ref={menuRef}
					>
						<nav className={styles.hamburgerMenu}>
							<div className={styles.inner}>
								<div className={styles.leftNav}>
									<MenuIcon open={isMenuOpen} onClick={handleOpenMenu} />

									<div className={styles.logoWrapper}>
										<LinkAnchor href="/" className={styles.logo} aria-label={t`Länk till startsidan`}>
											<Logo size="145x30" mobileSize="96x20" />
										</LinkAnchor>
										{showInternalVersionTag && <InternalVersionTag />}
									</div>

									<div className={styles.leftNavLinks}>
										{topLinkButtons?.map((btn) => <LinkButton key={btn._uid} {...btn} variant="link" />)}
									</div>
								</div>

								<div className={styles.rightNav}>
									{hasInfoBanner && (
										<ThemeProvider theme={getBannerTheme(infoBannerSeverity!)}>
											<Button
												className={styles.infoBannerBtn}
												onClick={toggleInfoBanner}
												aria-label={t`Öppna informationsmeddelande`}
											>
												<Icon name={getBannerIcon(infoBannerSeverity!)} />
											</Button>
										</ThemeProvider>
									)}
									<IconButton
										className={styles.searchToggleButton}
										iconName="search"
										aria-label={t`Öppna sökwidget`}
										onClick={handleOpenSearch}
									/>
									{contactLinkButton && (
										<LinkButton {...contactLinkButton} variant="link" className={styles.contactLinkButton} />
									)}
									<LinkButton
										href="https://apps.fortnox.se/fs/fs/login.php"
										variant="filled"
										className={styles.loginButton}
									>
										{t`Logga in`}
									</LinkButton>
								</div>
							</div>
						</nav>

						<MenuContentDesktop linkGroups={linkGroups} className={styles.menuContentDesktop} />
						<MenuContentMobile linkGroups={linkGroups} className={styles.menuContentMobile} />

						<div className={styles.footer}>
							{isMenuOpen && <div className={styles.overlay} />}
							<LinkButton
								href="https://apps.fortnox.se/fs/fs/login.php"
								variant="text"
								arrow={false}
								className={styles.loginButtonMobile}
								tabIndex={isMenuOpen ? 0 : -1}
							>
								{t`Logga in`}
							</LinkButton>
						</div>
					</header>
				</FocusLock>
			</RemoveScroll>
		</ThemeProvider>
	);
};

GlobalMenuV3.blokProps = blokProps;
