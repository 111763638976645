import { createContext, useContext } from 'react';
import { CisionPost } from './types';

export const useLatestPressRelease = () => {
	return useContext(Context);
};

interface ContextProps {
	latestPressRelease?: CisionPost;
}

const Context = createContext<ContextProps>({});

interface Props {
	children: React.ReactNode;
	value: CisionPost;
}

export const LatestPressReleaseProvider: React.FC<Props> = ({ children, value }) => {
	return <Context.Provider value={{ latestPressRelease: value }}>{children}</Context.Provider>;
};
