import { Button, Carousel } from '@/atoms';
import { CardMediumUspGroupStoryblok, CardMediumUspStoryblok } from '@/components';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Themes } from '@/types';
import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { CardMediumUsp } from '../CardMediumUsp/CardMediumUsp';
import styles from './CardMediumUspGroup.module.scss';

interface Props {
	blok: CardMediumUspGroupStoryblok;
	meta?: { theme?: Themes['theme'] };
}

export const CardMediumUspGroup: React.FC<Props> = ({ blok, meta }) => {
	const showCarousel = blok.cards.length < 5;
	const isDesktop = useMediaQuery('(min-width: 1919px)');
	const [showAllCards, setShowAllCards] = useState(false);
	const cardGroupRef = useRef<HTMLDivElement | null>(null);
	const shouldHaveMaxTwoColumns = blok.cards.length == 4 || !isDesktop;
	const [cards, setCards] = useState<CardMediumUspStoryblok[]>([]);

	useEffect(() => {
		if (showAllCards || !blok.enableShowMoreButton) {
			setCards(blok.cards);
			return;
		}
		if (shouldHaveMaxTwoColumns) {
			const firstTwoCards = blok.cards.slice(0, 2);
			setCards(firstTwoCards);
		} else {
			const firstThreeCards = blok.cards.slice(0, 3);
			setCards(firstThreeCards);
		}
	}, [shouldHaveMaxTwoColumns, showAllCards, blok.enableShowMoreButton, blok.cards]);

	const shouldShowButton = blok.enableShowMoreButton && (cards.length != blok.cards.length || showAllCards);
	const buttonText = showAllCards ? blok.buttonTextShowLess : blok.buttonTextShowMore;

	return (
		<>
			<div className={cls(showCarousel && styles.cardGroupCarousel)}>
				<div
					ref={cardGroupRef}
					className={cls(
						styles.cardGroup,
						(showAllCards || !blok.enableShowMoreButton) && shouldHaveMaxTwoColumns && styles.cardGroupSmall,
					)}
				>
					{cards.map((card) => {
						return <CardMediumUsp key={card._uid} {...CardMediumUsp.blokProps({ blok: card, meta })} />;
					})}
				</div>
				{shouldShowButton && (
					<div className={styles.button}>
						<Button
							onClick={() => {
								const elementPosition = showAllCards
									? cardGroupRef.current?.getBoundingClientRect()?.top
									: cardGroupRef.current?.getBoundingClientRect()?.bottom;
								const top = (elementPosition || 0) + window.pageYOffset - 200;
								window.scrollTo({ top, behavior: 'smooth' });
								setShowAllCards(!showAllCards);
							}}
						>
							{buttonText}
						</Button>
					</div>
				)}
			</div>
			{showCarousel && (
				<Carousel className={styles.carousel} showIndicators={true}>
					{blok.cards.map((card, index) => {
						return (
							<div key={card._uid} className={styles.card}>
								<CardMediumUsp data-index={index} {...CardMediumUsp.blokProps({ blok: card, meta })} />
							</div>
						);
					})}
				</Carousel>
			)}
		</>
	);
};
