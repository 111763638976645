import { Card, Heading, Text } from '@/atoms';
import { IconPlus } from '@/atoms/IconPlus/IconPlus';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps, buildLinkProps } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import { CardSmallStoryblok, RichtextStoryblok } from '@/components';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useThemeContext } from '@/contexts/theme';
import { cls, isRichtextEmpty } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import React from 'react';
import { IconPlusName } from 'icons';
import styles from './CardSmall.module.scss';

interface BlokProps {
	blok: CardSmallStoryblok;
	meta: {
		layout?: CardSmallProps['layout'];
		buttonVariant?: CardSmallProps['buttonVariant'];
		opacity?: boolean;
		titleSize?: 'h3' | 'h4' | 'h5';
		padding?: boolean;
	};
}

const blokProps = ({ blok, meta }: BlokProps): CardSmallProps => ({
	icon: blok?.icon?.[0] && blok.icon[0].name,
	title: blok.title,
	smallTitle: blok.smallTitle,
	titleSize: meta.titleSize,
	text: blok.text,
	linkButton: blok?.linkButton?.map((btn) => LinkButton.blokProps({ blok: btn })),
	layout: meta.layout ?? 'default',
	buttonVariant: meta.buttonVariant ?? 'filled',
	opacity: meta?.opacity,
	cardLink: (blok?.cardLink?.url || blok?.cardLink?.cached_url) && buildLinkProps(blok.cardLink)?.href,
	_editable: blok._editable,
	padding: meta.padding,
	_uid: blok._uid,
});

export interface CardSmallProps {
	icon?: IconPlusName;
	smallTitle?: string;
	title: string;
	titleSize?: 'h3' | 'h4' | 'h5';
	text?: string | RichtextStoryblok;
	linkButton?: LinkButtonProps[];
	opacity?: boolean;
	layout?: 'default' | 'center';
	buttonVariant: 'filled' | 'outlined' | 'text';
	cardLink?: string;
	padding?: boolean;
	_editable?: string;
	_uid: string;
}

export const CardSmall: Blok<CardSmallProps, BlokProps> = ({
	icon,
	smallTitle,
	title,
	titleSize = 'h5',
	text,
	linkButton,
	opacity = false,
	layout = 'default',
	buttonVariant = 'filled',
	cardLink,
	padding,
	_uid,
	_editable,
}) => {
	const { applyChildTheme } = useThemeContext();
	const { setTargetInteraction } = useImpressionTracking();
	const noText = !text || isRichtextEmpty(text);
	const noBtn = !linkButton || linkButton?.length === 0;
	const textMarginBottom = noBtn ? 'none' : 'xl';

	return (
		<Card
			className={cls(styles.card, (applyChildTheme || padding) && styles.padding, opacity && styles.opacity)}
			layout={layout}
			padding="custom"
			border={false}
			{...editableBlok({ _editable })}
			cardLink={cardLink}
		>
			{icon && (
				<div className={styles.iconWrapper}>
					<IconPlus name={icon} size="lg" />
				</div>
			)}

			{smallTitle && <Heading as="div" size="h6" title={smallTitle} marginBottom="xs" />}

			<Heading as="div" size={titleSize} title={title} marginBottom={noText ? 'xl' : 'md'} />

			{text &&
				(typeof text === 'string' ? (
					<Text marginBottom={textMarginBottom}>{text}</Text>
				) : (
					renderRichText(text, { textMarginBottom: textMarginBottom })
				))}

			{!cardLink && (
				<div className={cls(styles.btnWrapper, layout === 'center' && styles.btnCenter)}>
					{linkButton?.map((btn, index) => (
						<LinkButton
							key={`${_uid}-btn-${index}`}
							{...btn}
							variant={index === 1 && buttonVariant !== 'text' ? 'outlined' : buttonVariant}
							onClick={() => {
								setTargetInteraction({ contentTarget: btn.children as string });
							}}
						/>
					))}
				</div>
			)}
		</Card>
	);
};

CardSmall.blokProps = blokProps;
