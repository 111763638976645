import { Icon, IconType } from '@/atoms/Icon';
import { Colors } from '@/colors';
import { cls } from '@/utils';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconName } from '../../icons/types';
import styles from './IconButton.module.scss';

interface Props {
	/**
	 * The icon name
	 */
	iconName: IconName;

	/**
	 * If the icon type is light, solid or regular
	 *
	 * The custom type is for custom icons added as images hosted in **ui/public/icons**
	 */
	iconType?: IconType;

	/**
	 * The icon color.
	 */
	iconColor?: Colors;

	/**
	 * The icon size.
	 */
	iconSize?: SizeProp;

	/**
	 * A function that will be invoked when the user clicks the component.
	 */
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

	/**
	 * Sets whether or not the button is disabled.
	 */
	disabled?: boolean;

	className?: string;

	testID?: string;

	/**
	 * A translated short label describing what a click on this button does.
	 * Shown to screen readers and useful for end-to-end tests as well.
	 */
	'aria-label': string;
	tabIndex?: number;
}

/**
 * A clickable Icon within a button element
 */
export const IconButton: React.FC<Props> = ({
	'aria-label': ariaLabel,
	iconName,
	iconType = 'light',
	iconColor,
	iconSize = 'sm',
	className,
	onClick,
	disabled,
	testID,
	tabIndex = 0,
}) => {
	return (
		<button
			type="button"
			className={cls(styles.button, className)}
			onClick={onClick}
			data-testid={testID}
			aria-label={ariaLabel}
			disabled={disabled}
			tabIndex={tabIndex}
		>
			<Icon
				name={iconName}
				type={iconType}
				color={iconColor}
				className={styles.icon}
				disabled={disabled}
				size={iconSize}
			/>
		</button>
	);
};
