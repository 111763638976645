import { Icon } from '@/atoms';
import { updateRoute } from '@/bloks/Page/NavigationPageEducation/EducationFilter/updateRoute';
import { useRouter } from 'next/router';
import React from 'react';
import cls from 'classnames';
import { t } from 'ttag';
import { Item } from './ItemFilter';
import styles from './SelectedFilters.module.scss';

interface Props {
	selectedFilters: Record<string, Item[]>;
}

export const SelectedFilters: React.FC<Props> = ({ selectedFilters }) => {
	const router = useRouter();

	const handleRemoveFilter = (queryParam: string, item: Item) => {
		const updatedFilters = {
			...selectedFilters,
			[queryParam]: selectedFilters[queryParam].filter((selectedItem) => selectedItem.name !== item.name),
		};

		const value = updatedFilters[queryParam].map((selectedItem) => selectedItem.name).join(';');
		updateRoute(value, queryParam, router);
	};

	const selectedItemsExists = Object.values(selectedFilters).some((items) => items.length > 0);
	return (
		<div className={cls(styles.selectedFilters, !selectedItemsExists && styles.hiddenFilter)}>
			{selectedItemsExists && <div className={styles.title}>{t`Valda filter:`}</div>}
			{Object.entries(selectedFilters).map(([queryParam, items]) =>
				items.map((item) => (
					<div key={item.name} className={styles.filterItem}>
						<span>{item.name}</span>
						<div className={styles.icon} onClick={() => handleRemoveFilter(queryParam, item)}>
							<Icon name="fa-close" />
						</div>
					</div>
				)),
			)}
		</div>
	);
};
