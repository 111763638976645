import { Chip } from '@/atoms';
import { Choice, filterMatch } from '@/bloks/Page/NavigationPageFindAccountants/MultiChoiceFilter/MultiChoiceFilter';
import { capitalizeEachWord } from '@/utils';
import React from 'react';
import styles from './AccountantChips.module.scss';

interface Props {
	description?: string;
	filterChoices: Choice[];
}

export const AccountantChips: React.FC<Props> = ({ description, filterChoices }) => {
	const chips = filterChoices?.filter((value) => description && filterMatch([value], description));
	return (
		chips.length > 0 && (
			<div className={styles.chipsContainer}>
				{chips.map((choice) => {
					return (
						<Chip
							color={'--bg-dark-green-extra-light'}
							key={choice.key}
							text={capitalizeEachWord(choice.key)}
							size={'small'}
							className={styles.accountantChip}
						/>
					);
				})}
			</div>
		)
	);
};
