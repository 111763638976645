import { useContrast, useTheme } from '@/contexts/theme';
import classNames from 'classnames';
import styles from './BrandLine.module.scss';

interface Props {
	className?: string;

	marginBottom?: 'md' | 'xl';

	/**
	 * addContrast is used to add contrast to elements placed on an image or video
	 */
	addContrast?: boolean;
}

export const BrandLine: React.FC<Props> = ({ className, marginBottom = 'md', addContrast }) => {
	const theme = useTheme();
	const contrastTheme = useContrast();
	let themeInfo = theme;

	if (addContrast && contrastTheme) {
		themeInfo = contrastTheme;
	}

	return (
		<div
			className={classNames(
				styles.line,
				styles[`margin-bottom--${marginBottom}`],
				themeInfo.styles.brandlineColor,
				className,
			)}
		/>
	);
};
