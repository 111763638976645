import { useContrast, useTheme } from '@/contexts/theme';
import { cls, slugifyName } from '@/utils';
import { useRouter } from 'next/router';
import React, { ReactElement, ReactFragment, useEffect, useMemo, useRef } from 'react';
import styles from './Heading.module.scss';

interface Props {
	/**
	 * Element/component to render the heading as
	 */
	as: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

	/**
	 * Optional size override escape hatch
	 * Can set size="custom" and then provide className instead
	 *
	 *         desktop  mobile
	 *     h1   80px     64px
	 *     h2   48px     48px
	 *     h3   32px     32px
	 *     h4   24px     24px
	 *     h5   20px     20px
	 *     h5   20px     20px
	 */
	size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'custom';

	title: string | ReactElement | ReactFragment;

	/**
	 * Emphasized part of title
	 */
	titlePart2?: string;

	/**
	 * Optional marginBottom override escape hatch
	 * Can set marginBottom="custom" and then provide className instead
	 *
	 * 		none	0px		0rem
	 * 		2xs		4px		0.25rem
	 * 		xs		8px		0.5rem
	 * 		sm		12px	0.75rem
	 * 		md		16px	1rem
	 * 		lg		24px	1.5rem
	 * 		xl		32px	2rem
	 * 		2xl		48px	3rem
	 * 		3xl		64px	4rem
	 *
	 *
	 */
	marginBottom?: 'none' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'custom';
	/**
	 * Break title in two lines
	 */
	linebreak?: boolean;

	/**
	 * See 'as' and 'size' props before providing custom styling
	 */
	className?: string;
	/**
	 * id automatically set to a url safe slug based on title so that the heading
	 * can be anchor linked to. To disable this behavior pass `false`.
	 */
	id?: false;
	/**
	 * addContrast is used to add contrast to elements placed on an image or video
	 */
	addContrast?: boolean;
	smallTitle?: boolean;
	exo2?: boolean;
	noId?: boolean;
	articleSize?: boolean;
}

const getArticleSize = (as: Props['as']): Props['size'] => {
	if (as === 'h2') return 'h3';
	if (as === 'h3') return 'h4';
	if (as === 'h5') return 'h6';
	return as as Props['size'];
};

export const Heading: React.FC<Props> = ({
	as: Component,
	size,
	title,
	titlePart2,
	marginBottom = 'md',
	linebreak = false,
	className,
	addContrast = false,
	smallTitle = false,
	exo2 = false,
	noId = false,
	articleSize = false,
	...rest
}) => {
	const router = useRouter();
	const ref = useRef<HTMLDivElement | null>(null);
	if (!size && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(Component as any)) {
		size = Component as any;
	}

	const applyHeavitas = (size === 'h1' || size === 'h2' || size === 'h3') && !exo2;

	const id = useMemo(() => {
		if (rest.id === false || noId) return;
		return getHeadingAnchorId(title, titlePart2);
	}, [rest.id, title, titlePart2, noId]);

	useEffect(() => {
		if (!id || !ref.current) {
			return;
		}

		const path = router.asPath;

		if (path && path.includes('#')) {
			const anchorId = path?.split('#')?.[1];

			if (id === anchorId) {
				ref.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, [router.asPath, id]);

	const contrastTheme = useContrast();
	const theme = useTheme();
	const themeInfo = addContrast && contrastTheme ? contrastTheme : theme;
	const longWordLength = 14;
	let containsLongWord = false;

	if ((size === 'h1' || size === 'h2') && applyHeavitas) {
		containsLongWord =
			typeof title === 'string' &&
			title
				.concat(' ', titlePart2 ?? '')
				.split(' ')
				.some((word) => word.length > longWordLength);
	}

	if (articleSize) {
		size = getArticleSize(Component);
	}

	return (
		<Component
			className={cls(
				styles.common,
				applyHeavitas && styles.heavitas,
				applyHeavitas && containsLongWord && styles.titleWithLongWord,
				size && styles[`size--${size}`],
				titlePart2 && styles.titlePart1,
				smallTitle ? themeInfo.styles.smallTitleColor : themeInfo.styles.titleColor,
				marginBottom && marginBottom !== 'custom' && styles[`margin-bottom--${marginBottom}`],
				className,
			)}
			{...rest}
			id={id}
			ref={ref}
		>
			{title}
			{titlePart2 && linebreak && (
				<>
					<br />
					<span
						className={cls(styles.titlePart2, Component === 'h1' && themeInfo.styles.titlePartTwoColor)}
					>{` ${titlePart2}`}</span>
				</>
			)}
			{titlePart2 && !linebreak && (
				<span
					className={cls(styles.titlePart2, Component === 'h1' && themeInfo.styles.titlePartTwoColor)}
				>{` ${titlePart2}`}</span>
			)}
		</Component>
	);
};

export function getHeadingAnchorId(title: string | ReactElement | ReactFragment, titlePart2?: string) {
	return titlePart2
		? `${slugifyName(getIdString(title))}-${slugifyName(getIdString(titlePart2))}`
		: slugifyName(getIdString(title));
}

function getIdString(children: any) {
	if (typeof children === 'string') {
		return slugifyName(children);
	}

	const stringArray: string[] = [];

	if (children?.length) {
		children?.forEach((child: any) => {
			if (typeof child === 'string') {
				stringArray.push(child);
			}
			if (child?.props) {
				if (typeof child.props.children === 'string') {
					stringArray.push(child.props.children);
				} else if (typeof child.props.children !== 'object') {
					child.props.children?.forEach((c: any) => {
						if (c !== ' ') {
							stringArray.push(c);
						}
					});
				}
			}
		});
	}

	return stringArray.join(' ');
}
