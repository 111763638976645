import { Heading, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { CardArticleStoryblok } from '@/components';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { editableBlok } from '@/utils/storyblok';
import styles from './CardArticle.module.scss';

interface BlokProps {
	blok: CardArticleStoryblok;
}

const blokProps = ({ blok }: BlokProps): CardArticleProps => ({
	image: blok.image,
	label: blok.label,
	title: blok.title,
	text: blok.text,
	linkButton: blok.linkButton?.[0] && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	_uid: blok._uid,
	_editable: blok._editable,
});

export interface CardArticleProps {
	image?: ImageAssetProps;
	label?: string;
	title: string;
	text?: string;
	linkButton?: LinkButtonProps;
	_uid?: string;
	_editable?: string;
}

export const CardArticle: Blok<CardArticleProps, BlokProps> = ({
	image,
	label,
	title,
	text,
	linkButton,
	_editable,
}) => {
	return (
		<div {...editableBlok({ _editable })}>
			{image && (
				<div className={styles.image}>
					<ImageAsset asset={image} />
				</div>
			)}
			<div className={styles.content}>
				<Heading as="h3" size="h5" title={title} marginBottom="xs" />
				{label && (
					<Text muted bold>
						{label}
					</Text>
				)}
				<Text marginBottom={linkButton ? 'md' : 'none'}>{text}</Text>
				{linkButton && <LinkButton {...linkButton} variant="text" />}
			</div>
		</div>
	);
};

CardArticle.blokProps = blokProps;
