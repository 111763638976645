import { ContainerFluid, Heading, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { HeaderMiniStoryblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './HeaderMini.module.scss';

interface BlokProps {
	blok: HeaderMiniStoryblok;
}

interface Props {
	title: string;
	_editable?: string;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	title: blok.title,
	_editable: blok._editable,
});

export const HeaderMini: Blok<Props, BlokProps> = ({ title, _editable }) => {
	const { menuVersionV3 } = useGlobalMenu();
	const { hasSubMenuV2 } = useSubMenu();
	return (
		<ContainerFluid theme="white" {...editableBlok({ _editable })}>
			<Section as="header" paddingY={{ base: 'none' }} maxWidth="1296px">
				<div
					className={cls(styles.container, menuVersionV3 && styles.menuVersionV3, hasSubMenuV2 && styles.hasSubMenuV2)}
				>
					{title && <Heading as="h1" title={title} marginBottom="none" className={styles.title} />}
				</div>
			</Section>
		</ContainerFluid>
	);
};

HeaderMini.blokProps = blokProps;
