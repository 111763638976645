import { Icon, Text } from '@/atoms';
import { ProductUspStoryblok } from '@/components';
import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import React from 'react';
import styles from './ProductUsp.module.scss';

interface BlokProps {
	blok: ProductUspStoryblok;
}

const blokProps = ({ blok }: BlokProps): ProductUspProps => ({
	text: blok.text,
	_editable: blok._editable,
});

export interface ProductUspProps {
	text: string;
	anti?: boolean;
	_editable?: string;
}

export function ProductUsp({ text, anti }: ProductUspProps) {
	const themeInfo = useTheme();

	return (
		<ul className={cls(styles.container, anti && themeInfo.styles.muted)}>
			<li>
				<Icon
					name={anti ? 'circle-xmark' : 'circle-check'}
					className={cls(styles.icon, anti && themeInfo.styles.antiIconColor)}
				/>
				<Text marginBottom="none">{text}</Text>
			</li>
		</ul>
	);
}

ProductUsp.blokProps = blokProps;
