import { useTheme } from '@/contexts/theme';
import { ReactElement, useEffect, useState } from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import cls from 'classnames';
import { t } from 'ttag';
import styles from './Carousel.module.scss';

interface Props {
	children: React.ReactNode;
	showIndicators?: boolean;
	centerMode?: boolean;
	selectedIndex?: number;
	showThumbs?: boolean;
	onChange?: (index: number) => void;
	className?: string;
	showArrows?: boolean;
	centerSlidePercentage?: number;
	infiniteLoop?: boolean;
}

/**
 * The Carousel  is a component for cycling through elements, like a carousel (slideshow).
 */
export const Carousel: React.FC<Props> = ({
	onChange,
	className,
	showIndicators = false,
	centerMode = true,
	selectedIndex = 0,
	showThumbs = false,
	centerSlidePercentage = 85,
	children,
	showArrows = false,
	infiniteLoop = false,
}) => {
	const [pageLoaded, setPageLoaded] = useState(false);
	const themeInfo = useTheme();

	useEffect(() => {
		setPageLoaded(true);
	}, []);

	return pageLoaded ? (
		<ResponsiveCarousel
			className={cls(styles.carousel, className)}
			centerMode={centerMode}
			showArrows={showArrows}
			showStatus={false}
			centerSlidePercentage={centerSlidePercentage}
			showIndicators={showIndicators}
			selectedItem={selectedIndex}
			showThumbs={showThumbs}
			onChange={onChange}
			preventMovementUntilSwipeScrollTolerance
			swipeScrollTolerance={80}
			infiniteLoop={infiniteLoop}
			renderIndicator={(onClickHandler, isSelected, index) => {
				return (
					<li
						key={index}
						className={cls(
							styles['slide-indicator'],
							isSelected && styles['selected'],
							index == 1 && styles['extraSpace'],
							themeInfo.styles.carouselIndicatorColor,
						)}
						onClick={onClickHandler}
						onKeyDown={onClickHandler}
						aria-label={t`Gå till vy ${index}`}
					/>
				);
			}}
		>
			{children as Array<ReactElement>}
		</ResponsiveCarousel>
	) : (
		<></>
	);
};
