import { Link, Text } from '@/atoms';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import styles from './ArticleMini.module.scss';

export interface ArticleMiniProps {
	title: string;
	category?: string;
	image?: ImageAssetProps;
	fullSlug: string;
	_uid: string;
}

export const ArticleMini: React.FC<ArticleMiniProps> = ({ title, category, image, fullSlug }) => {
	return (
		<Link href={fullSlug}>
			<article className={styles.article}>
				<div className={styles.textWrapper}>
					<Text size="ingress">{title}</Text>
					<Text bold muted marginBottom="none">
						{category}
					</Text>
				</div>
				<div className={styles.image}>{image?.filename && <ImageAsset asset={image} absolute />}</div>
			</article>
		</Link>
	);
};
