import { NextRouter } from 'next/router';

export const updateRoute = (value: string, queryParam: string, router: NextRouter) => {
	const newQuery = { ...router.query };

	if (value) {
		newQuery[queryParam] = value;
	} else {
		delete newQuery[queryParam];
	}
	router.push(
		{
			pathname: router.pathname,
			query: newQuery,
		},
		undefined,
		{ shallow: true },
	);
};
