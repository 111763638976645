import { Chip, Heading } from '@/atoms';
import { useIntegrationCurrent } from '@/contexts/Integration';
import React from 'react';
import styles from '../IntegrationPage.module.scss';

const TagCloud: React.FC = () => {
	const { integration } = useIntegrationCurrent();

	if (!integration.tags?.length) return null;

	return (
		<div className={styles.tags}>
			<Heading as="h3" size="h4" title="Funktioner" />
			<div className={styles.tagsList}>
				{integration.tags.map((tag, index) => (
					<Chip key={`point-${index}`} className={styles.tag} text={tag} />
				))}
			</div>
		</div>
	);
};

export default TagCloud;
