import { Colors, getColor } from '@/colors';
import { ThemeV2Storyblok } from '@/components';
import { useServerPageConfig } from '@/contexts/config';
import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import { FlipProp, RotateProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';
import { customIconNames } from '../../icons/custom';
import { CustomIconName, FaIconName } from '../../icons/types';
import styles from './Icon.module.scss';

type IconPrefix = 'fas' | 'fab' | 'fal';

const types: Record<IconType, IconPrefix> = {
	light: 'fal',
	brand: 'fab',
	solid: 'fas',
};

export type IconType = 'light' | 'brand' | 'solid';

export interface BaseIconProps {
	rotation?: RotateProp;

	/**
	 * The size of the Icon
	 *
	 * 		xs		12px	0.75rem
	 * 		sm		20px	1.25rem
	 * 		lg		28px	1.75rem
	 * 		1x		16px	1rem
	 * 		2x		32px	2rem
	 * 		3x		48px	3rem
	 * 		4x		64px	4rem
	 * 		5x		80px	5rem
	 * 		6x		96px	6rem
	 * 		7x		112px	7rem
	 * 		8x		128px	8rem
	 * 		9x		144px	9rem
	 *		10x		160px	10rem
	 */
	size?: SizeProp;
	/**
	 * Sets opacity of the Icon to 0.3
	 */
	disabled?: boolean;
	className?: string;
	testID?: string;
	storybook?: boolean;
	storybookTheme?: ThemeV2Storyblok['theme'];
}

type CustomIconProps = BaseIconProps & {
	name: CustomIconName;
};

export type IconProps = BaseIconProps & {
	/**
	 * If the icon type is light, solid or regular
	 */
	type?: IconType;
	name: FaIconName | CustomIconName;
	/**
	 * Use to override default theme color
	 */
	color?: Colors;
	flip?: FlipProp;
};

/**
 * Icon component for rendering a Font awesome 6 icon or a custom icon
 *
 * Delegates to {@link CustomIcon} when name is in {@link customIconNames}.
 */
export const Icon: React.FC<IconProps> = ({
	className,
	color,
	disabled,
	flip,
	name,
	rotation,
	size = 'sm',
	storybook,
	testID,
	type = 'light',
}) => {
	const themeInfo = useTheme();

	if ((customIconNames as any).includes(name)) {
		return (
			<CustomIcon
				className={className}
				name={name as CustomIconName}
				rotation={rotation}
				size={size}
				storybook={storybook}
				testID={testID}
			/>
		);
	}

	const iconColor = color ? getColor(color) : undefined;
	return (
		<FontAwesomeIcon
			icon={[types[type], name] as any}
			flip={flip}
			size={size}
			rotation={rotation}
			style={{ color: iconColor, opacity: disabled ? 0.3 : 1 } as CSSProperties}
			className={cls(
				styles.icon,
				styles[`fa-${size}`],
				styles[`fa-rotate-${rotation}`],
				iconColor ? iconColor : themeInfo.styles.iconColor,
				className,
			)}
			data-testid={testID}
		/>
	);
};

/**
 * Custom SVG Icon hosted in `ui/public/icons`
 */
export const CustomIcon: React.FC<CustomIconProps> = ({
	className,
	name,
	rotation,
	size = 'md',
	storybook = false,
	testID,
}) => {
	const { siteBaseUrl } = useServerPageConfig();
	const customIconPath = new URL(`/icons/${name}.svg`, siteBaseUrl).href;
	return (
		<span className={cls(className, styles.customIconContainer, styles[`fa-${size}`], styles[`fa-rotate-${rotation}`])}>
			<Image
				src={storybook ? customIconPath : `/icons/${name}.svg`}
				alt={`Ikon ${name}`}
				fill
				objectFit="contain"
				data-testid={testID}
				className={cls(styles[`custom-${name}`], styles.customIcon)}
			/>
		</span>
	);
};
