import { PageLayout } from '@/atoms';
import { CardFiveGroupStoryblok, LandingPageStoryblok, SubMenuStoryblok } from '@/components';
import { ISbStoryData } from '@/types/storyblok';
import React from 'react';
import DynamicComponent from '../DynamicComponent';

interface Props {
	blok: LandingPageStoryblok;
	meta: Record<string, any>;
}

export const LandingPage: React.FC<Props> = ({ blok, meta }) => {
	const { zone1, zone2, zone3, zone4, zone5 } = blok;
	const subMenu = blok?.subMenu as unknown as ISbStoryData<SubMenuStoryblok>;
	const contact = blok?.contact as unknown as ISbStoryData<CardFiveGroupStoryblok>;
	const sections = [...(blok.header || []), ...(blok.sections || [])];

	return (
		<PageLayout subMenu={subMenu} exits={contact} meta={meta}>
			{sections?.map((blok, index) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: index + 1 }} />)}
			{zone1?.map((blok: any) => (
				<DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 1, hasSubMenu: !!subMenu }} />
			))}

			{zone2?.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 2 }} />)}

			{zone3?.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 3 }} />)}

			{zone4?.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 4 }} />)}

			{zone5?.map((blok: any) => <DynamicComponent blok={blok} key={blok._uid} meta={{ zone: 5 }} />)}
		</PageLayout>
	);
};
