import { Heading } from '@/atoms';
import { SubMenuGroupStoryblok, SubMenuItemStoryblok, SubMenuV2Storyblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { removeFirstAndLastSlash } from '@/utils';
import { usePathname } from 'next/navigation';
import { ISbStoryData } from 'storyblok-js-client';
import { useEffect, useRef, useState } from 'react';
import styles from './SubMenuV2.module.scss';
import { SubMenuDesktop } from './components/SubMenuDesktop/SubMenuDesktop';
import { SubMenuMobile } from './components/SubMenuMobile/SubMenuMobile';

interface Props {
	subMenu: ISbStoryData<SubMenuV2Storyblok>;
}

const findActiveItem = (
	items: SubMenuV2Storyblok['items'],
	pathname: string | null,
): SubMenuGroupStoryblok | SubMenuItemStoryblok | null => {
	for (const item of items) {
		if (removeFirstAndLastSlash(item.link.cached_url) === removeFirstAndLastSlash(pathname)) {
			return item;
		}

		if (item.items) {
			const subItem = item.items.find(
				(subItem) => removeFirstAndLastSlash(subItem.link.cached_url) === removeFirstAndLastSlash(pathname),
			);
			if (subItem) {
				return subItem;
			}
		}
	}
	return null;
};

export const SubMenuV2: React.FC<Props> = ({ subMenu }) => {
	const pathname = usePathname();
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [hideGlobalMenu, setHideGlobalMenu] = useState<boolean>(false);
	const ticking = useRef(false);
	const subMenuContent = subMenu.content;
	const title = subMenuContent.title;
	const items = subMenuContent.items;
	const activeItem = findActiveItem(items, pathname);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;

			if (!ticking.current) {
				window.requestAnimationFrame(() => {
					const topOffset = document.body.scrollTop > 50 || document.documentElement.scrollTop > 50; // Distance from top
					const scrollOffset = Math.abs(currentScrollPos - prevScrollPos) > 30; // Scrolling distance

					if (topOffset && scrollOffset) {
						const isVisible = prevScrollPos < currentScrollPos;

						setHideGlobalMenu(isVisible);
						setPrevScrollPos(currentScrollPos);
					}
					ticking.current = false;
				});

				ticking.current = true;
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [prevScrollPos]);

	return (
		<ThemeProvider theme="submenuV2">
			<div className={styles.titleWrapper}>
				<Heading as="div" size="h5" title={title} marginBottom="none" />
			</div>
			<SubMenuDesktop
				items={items}
				hideGlobalMenu={hideGlobalMenu}
				activeItem={activeItem}
				className={styles.subMenuDesktop}
			/>
			<SubMenuMobile
				items={items}
				title={title}
				hideGlobalMenu={hideGlobalMenu}
				activeItem={activeItem}
				className={styles.subMenuMobile}
			/>
		</ThemeProvider>
	);
};
