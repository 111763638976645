import { BackgroundImage, ContainerFluid, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import DynamicComponent from '@/bloks/DynamicComponent';
import { HeaderCommon, HeaderCommonProps } from '@/bloks/Other';
import { SectionCardBackgroundStoryblok, ThemeContrastStoryblok, ThemeFullStoryblok } from '@/components';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAssetProps } from '@/metabloks';
import { getCorrespondingLegacyTheme } from '@/themes';
import { getPiwikTrackingSlug } from '@/trackers/piwik';
import { editableBlok } from '@/utils/storyblok';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';
import styles from './SectionCardBackground.module.scss';

interface BlokProps {
	blok: SectionCardBackgroundStoryblok;
	meta?: { zone?: number };
}

const blokProps = ({ blok, meta }: BlokProps): Props => {
	const theme = (getCorrespondingLegacyTheme(blok?.theme?.[0]?.theme) ?? 'white') as ThemeFullStoryblok['theme'];
	return {
		header: blok.header?.[0] && HeaderCommon.blokProps({ blok: { ...blok.header?.[0], centerContent: true } }),
		cards: blok.cards.map((cardGroup) => (
			<DynamicComponent
				key={cardGroup._uid}
				blok={cardGroup}
				meta={{ layout: 'center', padding: true, opacity: true }}
			/>
		)),
		backgroundImage: blok.backgroundImage,
		theme,
		component: blok.cards?.[0]?.component,
		addContrast: blok.addContrast?.[0]?.theme,
		_editable: blok._editable,
		linkButton: blok.linkButton?.[0]?.link && LinkButton.blokProps({ blok: blok.linkButton[0] }),
		zone: meta?.zone,
	};
};

interface Props {
	theme: ThemeFullStoryblok['theme'];
	header?: HeaderCommonProps;
	cards?: ReactNode[];
	component: string;
	addContrast?: ThemeContrastStoryblok['theme'];
	backgroundImage: ImageAssetProps;
	_editable?: string;
	linkButton?: LinkButtonProps;
	zone?: number;
}
export const SectionCardBackground: Blok<Props, BlokProps> = ({
	theme,
	header,
	cards,
	addContrast,
	backgroundImage,
	linkButton,
	_editable,
	zone,
}) => {
	// PIWIK IMPRESSION TRACKING
	const { ContentTracking, DataLayer } = usePiwikPro();
	const pathname = usePathname();
	const slug = getPiwikTrackingSlug(pathname ?? '');
	const contentName = `${zone}|SectionCardBackground`;
	const contentPiece = header?.titlePart2 ? `${header.titlePart1} ${header.titlePart2}` : header?.titlePart1;
	const contentTarget = 'multiple interactions possible';

	function trackContentInteraction(targetText: string) {
		DataLayer.push({
			event: 'contentInteraction',
			contentName: `${slug}|${contentName}`,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetText },
		});
		ContentTracking.trackContentInteraction(
			'contentInteraction',
			`${slug}|${contentName}`,
			contentPiece ?? 'no available title',
			contentTarget,
		);
	}
	return (
		<ImpressionTrackingProvider
			value={{
				contentName: `${zone}|SectionCardBackground`,
				contentPiece,
				contentTarget,
			}}
		>
			<ContainerFluid theme={theme} contrast={addContrast}>
				<Section {...editableBlok({ _editable })}>
					{header && <HeaderCommon className={styles.header} {...header} marginBottom="3xl" addContrast />}
					{cards}
					{backgroundImage?.filename && <BackgroundImage asset={backgroundImage} overlay={false} blur />}
					<div className={styles.buttonPosition}>
						{linkButton && (
							<LinkButton
								{...linkButton}
								variant="outlined"
								addContrast
								onClick={() => {
									trackContentInteraction((linkButton.children as string) ?? '');
								}}
							/>
						)}
					</div>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

SectionCardBackground.blokProps = blokProps;
