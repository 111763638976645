import { BackgroundImage, BrandLine, ContainerFluid, Heading, Section, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkToggle, LinkToggleProps } from '@/bloks/Button/LinkToggle';
import { PriceCardGroupV2 } from '@/bloks/PriceCard';
import {
	AssetStoryblok,
	HeaderConversionV2Storyblok,
	PriceCardGroupV2Storyblok,
	ThemeContrastStoryblok,
	ThemeV2Storyblok,
} from '@/components';
import { useAnimationContext } from '@/contexts/Animations';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset } from '@/metabloks';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './HeaderConversionV2.module.scss';

interface BlokProps {
	blok: HeaderConversionV2Storyblok;
	meta: {
		containerFoldoutButton: JSX.Element;
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	smallTitle: blok.smallTitle,
	title: blok.title,
	text: blok.text,
	backgroundImage: blok.backgroundImage,
	foregroundImage: blok.foregroundImage,
	linkToggle: blok.linkToggle?.[0] && LinkToggle.blokProps({ blok: blok.linkToggle[0] }),
	priceCardGroup: blok.priceCardGroup?.[0] && blok.priceCardGroup[0],
	theme: blok.theme?.[0]?.theme,
	backgroundPattern: blok.backgroundPattern,
	addContrast: blok.addContrast?.[0]?.theme,
	containerFoldoutButton: meta.containerFoldoutButton,
	_editable: blok._editable,
});

interface Props {
	smallTitle?: string;
	title: string;
	text?: string;
	backgroundImage?: AssetStoryblok;
	foregroundImage?: AssetStoryblok;
	linkToggle?: LinkToggleProps;
	priceCardGroup?: PriceCardGroupV2Storyblok;
	theme?: ThemeV2Storyblok['theme'];
	backgroundPattern?: boolean;
	addContrast?: ThemeContrastStoryblok['theme'];
	containerFoldoutButton?: JSX.Element;
	_editable?: string;
}

export const HeaderConversionV2: Blok<Props, BlokProps> = ({
	smallTitle,
	title,
	text,
	backgroundImage,
	foregroundImage,
	linkToggle,
	priceCardGroup,
	theme = 'mintGreen',
	backgroundPattern = false,
	addContrast,
	containerFoldoutButton,
	_editable,
}) => {
	const themeInfo = getThemeInfo(theme);
	const addBackgroundPattern = !!(priceCardGroup && foregroundImage?.filename);
	const hasBackgroundImage = !!backgroundImage?.filename;
	const { addAnimation } = useAnimationContext();
	const { menuVersionV3 } = useGlobalMenu();

	const contentName = '1|HeaderConversion';
	const contentPiece = title;

	return (
		<ImpressionTrackingProvider value={{ contentName, contentPiece }}>
			<ContainerFluid
				theme={theme}
				className={themeInfo.styles.bgColor}
				contrast={addContrast}
				backgroundPattern={addBackgroundPattern || backgroundPattern}
			>
				{backgroundImage?.filename && (
					<BackgroundImage
						asset={backgroundImage}
						objectPosition="center top"
						priority
						className={cls(
							styles.backgroundImage,
							addAnimation && 'animation',
							addBackgroundPattern && styles.withBackgroundPattern,
						)}
						blur
					/>
				)}

				<Section
					{...editableBlok({ _editable })}
					className={cls(styles.section, menuVersionV3 && styles.menuVersionV3)}
					paddingX={{ base: 'none' }}
					maxWidth="custom"
				>
					<div className={styles.inner}>
						<BrandLine marginBottom="xl" addContrast={hasBackgroundImage} />
						{smallTitle && <Heading as="h6" marginBottom="xl" title={smallTitle} addContrast={hasBackgroundImage} />}
						<Heading
							as="h1"
							title={title}
							marginBottom={text ? 'xl' : 'custom'}
							className={styles.title}
							addContrast={hasBackgroundImage}
						/>
						{text && (
							<Text className={styles.text} marginBottom="2xl" addContrast={hasBackgroundImage}>
								{text}
							</Text>
						)}
						{foregroundImage?.filename && (
							<div className={styles.foregroundImage}>
								<ImageAsset absolute asset={foregroundImage} objectFit="contain" fill />
							</div>
						)}
						{linkToggle && (
							<div className={cls(styles.linkToggle, priceCardGroup && styles.extraMarginBottom)}>
								<LinkToggle {...linkToggle} addContrast={hasBackgroundImage} />
							</div>
						)}
					</div>
					{priceCardGroup && (
						<div
							className={cls(
								styles.priceCardGroup,
								containerFoldoutButton && styles.marginBottom,
								linkToggle && styles.marginTop,
							)}
						>
							<PriceCardGroupV2
								blok={priceCardGroup}
								containerFoldoutButton={containerFoldoutButton}
								addContrast={hasBackgroundImage}
							/>
						</div>
					)}

					{containerFoldoutButton && <div className={styles.containerFoldoutButton}>{containerFoldoutButton}</div>}
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

HeaderConversionV2.blokProps = blokProps;
