import { Button, Icon, Text } from '@/atoms';
import { useServerPageConfig } from '@/contexts/config';
import { useSearchV2 } from '@/contexts/search/AddSearchProviderV2';
import { Env } from '@/server/config/types';
import { Hit } from 'addsearch-js-client';
import { useKeyPress } from 'hooks/useKeyPress';
import classNames from 'classnames';
import { t } from 'ttag';
import styles from './SearchWidgetV2.module.scss';
import { SearchField } from './components/SearchField';
import { SearchResultItem } from './components/SearchResultItem';

export const SearchWidgetV2: React.FC = () => {
	const { searchTerm, addSearchClient, searchResult, isSearchOpen, setSearchOpen, animation, setAnimation } =
		useSearchV2();
	const { env, externalDeployment } = useServerPageConfig();
	const isExternalProdEnvironment = env === Env.Prod && externalDeployment;
	const initalStateText = t`Var god gör din sökning i fältet ovan.`;
	const noResultText = t`Vi hittade tyvärr inget sökresultat.`;

	useKeyPress('Escape', undefined, () => {
		setSearchOpen(false);
		setAnimation(null);
	});

	const handleClick = (hit: Hit, index: number) => {
		if (isExternalProdEnvironment && addSearchClient)
			addSearchClient.sendStatsEvent('click', searchTerm, { documentId: hit.id, position: index });
	};

	const closeBtn = (
		<div className={styles.closeBtnWrapper}>
			<Button
				variant="link"
				onClick={() => {
					setSearchOpen(false);
					setAnimation('slideOut');
				}}
				type="button"
			>
				<div className={styles.closeBtnInner}>
					<Icon name="arrow-left" size="1x" />
					<span>{t`Till menyn`}</span>
				</div>
			</Button>
		</div>
	);

	return (
		<div
			className={classNames(
				styles.container,
				isSearchOpen && !animation && styles.open,
				animation === 'slideIn' && styles.slideIn,
				animation === 'slideOut' && styles.slideOut,
			)}
			role="search"
		>
			<div className={styles.mobile}>{closeBtn}</div>
			<SearchField className={styles.mobile} />
			<div className={styles.desktop}>{closeBtn}</div>

			<div className={styles.text}>
				{searchResult?.length < 1 && <Text>{searchTerm === '' ? initalStateText : noResultText}</Text>}
			</div>

			<div className={styles.searchResult}>
				{searchResult?.map((hit: Hit, index: number) => (
					<SearchResultItem key={hit.id} hit={hit} onClick={() => handleClick(hit, index + 1)} />
				))}
			</div>
		</div>
	);
};
