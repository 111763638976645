import { ContainerFluid, Grid, GridProps, Heading, Section, Text } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { renderRichText } from '@/bloks/RichText';
import {
	SectionTestimonialStoryblok,
	TestimonialArticleGlobalStoryblok,
	TestimonialGlobalStoryblok,
} from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ImpressionTrackingProvider } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ImageAsset } from '@/metabloks';
import { BigQuote } from '@/metabloks/BigQuote';
import { getPiwikTrackingSlug } from '@/trackers/piwik';
import { Themes } from '@/types';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { usePathname } from 'next/navigation';
import styles from './SectionTestimonial.module.scss';

interface BlokProps {
	blok: SectionTestimonialStoryblok;
	meta?: { zone?: number };
}

interface SectionTestimonialProps {
	title?: string;
	testimonial?: any[];
	articleTestimonial?: string;
	theme: Themes['theme'];
	component: string;
	_editable?: string;
	zone: number;
}

const blokProps = ({ blok, meta }: BlokProps) => ({
	title: blok.title,
	testimonial: blok.testimonial,
	articleTestimonial: blok.articleTestimonial,
	theme: blok.theme?.[0]?.theme,
	component: blok.component,
	_editable: blok._editable,
	zone: meta?.zone,
});

/**
 * Section testimonial for rendering testimonials.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=93%3A3499
 */
export const SectionTestimonial = ({
	title,
	testimonial,
	articleTestimonial,
	theme = 'darkGreen',
	_editable,
	zone,
}: SectionTestimonialProps) => {
	const testimonials = testimonial as any as ISbStoryData<TestimonialGlobalStoryblok>[];
	const articleTestimonialComponent = articleTestimonial as any as ISbStoryData<TestimonialArticleGlobalStoryblok>;
	const articleContent = articleTestimonialComponent?.content as any as TestimonialArticleGlobalStoryblok;
	const isSingleTestimonial = testimonials && testimonials.length === 1;
	const { hasSubMenuV1 } = useSubMenu();

	const componentTheme = articleTestimonial ? 'darkGreen' : theme;

	const useColumns = () => {
		if (articleTestimonialComponent) {
			return { base: 1, xl: 2 };
		}
		if (isSingleTestimonial) {
			return { base: 1, xxl: 1 };
		}
		return { base: 1, md: 2 };
	};
	const columns = useColumns();

	// PIWIK IMPRESSION TRACKING
	const { ContentTracking, DataLayer } = usePiwikPro();
	const pathname = usePathname();
	const slug = getPiwikTrackingSlug(pathname);
	const contentName = `${zone}|SectionTestimonial`;
	const contentPiece = title ?? 'no available title';
	const contentTarget = articleTestimonial ? 'one interaction possible' : 'no interactions possible';

	function trackContentInteraction(targetText: string) {
		DataLayer.push({
			event: 'contentInteraction',
			contentName: `${slug}|${contentName}`,
			contentPiece,
			contentTarget,
			contentInteraction: 'Click',
			data: { clickElementText: targetText },
		});
		ContentTracking.trackContentInteraction(
			'contentInteraction',
			`${slug}|${contentName}`,
			contentPiece || 'no available title',
			contentTarget,
		);
	}

	return (
		<ImpressionTrackingProvider
			value={{
				contentName,
				contentPiece: contentPiece || 'no available title',
				contentTarget,
			}}
		>
			<ContainerFluid theme={componentTheme}>
				<Section
					{...editableBlok({ _editable })}
					paddingX={hasSubMenuV1 ? { base: 'md', md: '4xl' } : undefined}
					paddingY={hasSubMenuV1 ? { base: '3xl', md: '3xl' } : undefined}
				>
					<div
						className={cls(styles.container, styles[`theme-${componentTheme}`], hasSubMenuV1 && styles.hasSubMenuV1)}
					>
						{(!isSingleTestimonial || articleTestimonialComponent) && title && (
							<Heading marginBottom="xl" as="h3" title={title} />
						)}
						{isSingleTestimonial && <SingleTestimonial testimonial={testimonials[0]} />}
						{articleTestimonialComponent && (
							<ArticleTestimonial
								article={articleContent}
								columns={columns}
								theme={componentTheme}
								trackContentInteraction={() => {
									trackContentInteraction(articleContent.name);
								}}
							/>
						)}
						{!isSingleTestimonial && !articleTestimonial && (
							<TestimonialGroup testimonials={testimonials} columns={columns} />
						)}
					</div>
				</Section>
			</ContainerFluid>
		</ImpressionTrackingProvider>
	);
};

SectionTestimonial.blokProps = blokProps;

interface SingleTestimonialProps {
	testimonial: ISbStoryData<TestimonialGlobalStoryblok>;
}

function SingleTestimonial({ testimonial }: SingleTestimonialProps) {
	const quote = testimonial?.content?.quote;

	return (
		<div
			className={cls(styles.quoteContainer, testimonial.content?.image?.filename && styles.singleTestimonialContainer)}
		>
			<div
				className={cls(
					!testimonial.content.image?.filename ? styles.noImageTextContainer : styles.testimonialTextContainer,
					testimonial.content?.quote && testimonial.content?.image?.filename && styles.longTextAlign,
				)}
			>
				{typeof quote === 'object' ? (
					<BigQuote className={styles.bigQuote} quote={quote} />
				) : (
					<Text as="div" size="ingress" className={cls(styles.bigQuote, styles.quote)}>
						{quote}
					</Text>
				)}
				{testimonial.content?.name && testimonial.content?.jobTitle && (
					<Heading
						as="div"
						size="h6"
						title={`${testimonial.content?.name}, ${testimonial.content?.jobTitle}`}
						marginBottom="none"
					/>
				)}
			</div>

			{testimonial.content?.image?.filename && (
				<div className={styles.imageTestimonialContainer}>
					<ImageAsset
						absolute
						asset={testimonial.content.image}
						fill
						objectFit="cover"
						objectPosition="top center"
						filters="smart"
					/>
				</div>
			)}
		</div>
	);
}

interface ArticleTestimonialProps {
	article: TestimonialArticleGlobalStoryblok;
	columns: GridProps['columns'];
	theme: Themes['theme'];
	trackContentInteraction: (targetText: string) => void;
}

function ArticleTestimonial({ article, columns, trackContentInteraction }: ArticleTestimonialProps) {
	return (
		<Grid className={cls(styles.articleTestimonial, styles.quoteGrid)} columns={columns}>
			<Grid.Item className={styles.articleContainer}>
				{article.intro && <Text size="ingress">{article.intro}</Text>}
				<Text as="div" marginBottom="xl">
					{renderRichText(article.summary)}
				</Text>
				<Heading as="div" size="h5" title={article.name} marginBottom="xl" />
				{article?.linkButton?.map((linkButton) => (
					<LinkButton
						key={linkButton._uid}
						{...LinkButton.blokProps({ blok: linkButton })}
						variant="text"
						className={styles.button}
						onClick={() => trackContentInteraction(linkButton.text ?? '')}
					/>
				))}
			</Grid.Item>
			<Grid.Item className={styles.imageContainer}>
				{article?.image?.filename && (
					<div className={styles.image}>
						<ImageAsset
							absolute
							asset={article?.image}
							objectFit="cover"
							fill
							objectPosition="top center"
							filters="smart"
						/>
					</div>
				)}
			</Grid.Item>
		</Grid>
	);
}
interface TestimonialGroupProps {
	testimonials: ISbStoryData<TestimonialGlobalStoryblok>[];
	columns: GridProps['columns'];
}
function TestimonialGroup({ testimonials, columns }: TestimonialGroupProps) {
	return (
		<Grid className={styles.quoteGrid} columns={columns}>
			{testimonials?.map((testimonial, index) => {
				const quote = testimonial.content?.quote;
				return (
					<Grid.Item key={index}>
						<div className={styles.quoteContainer}>
							<div
								className={cls(
									!testimonial.content.image ? styles.noImageTextContainer : styles.testimonialTextContainer,
									quote && testimonial.content?.image?.filename && styles.longTextAlign,
								)}
							>
								{typeof quote === 'object' ? (
									<Text as="div" size="ingress" className={styles.quoteRichtext}>
										{renderRichText(quote)}
									</Text>
								) : (
									<Text size="ingress" className={styles.quote}>
										{quote}
									</Text>
								)}
							</div>
							{testimonial.content?.name && (
								<div className={styles.flexWrapper}>
									<Heading
										as="div"
										size="h5"
										title={testimonial.content?.name}
										className={styles.name}
										marginBottom="none"
									/>
									{testimonial.content?.jobTitle && (
										<Text className={styles.title}>{testimonial.content?.jobTitle}</Text>
									)}
								</div>
							)}
						</div>
					</Grid.Item>
				);
			})}
		</Grid>
	);
}
