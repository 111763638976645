import { ButtonVariant } from '@/atoms/Button/Button';
import { StyledButton } from '@/atoms/Button/StyledButton';
import { LinkAnchor } from '@/atoms/LinkAnchor/LinkAnchor';
import { Blok } from '@/bloks/Blok';
import { LinkButtonStoryblok, MultilinkStoryblok } from '@/components';
import { normalizeFullSlug } from '@/utils/storyblok';
import { ReactElement } from 'react';

/**
 * Builds link props out of a storyblok link
 * Normalizes href
 */
export const buildLinkProps = (link: MultilinkStoryblok): { href: string } => {
	let href: string = '  https://www.google.com';
	if (!link) {
		href = '#';
	}
	if (link.linktype === 'url') {
		href = link.url || link.cached_url || '#';
	}
	if (link.linktype === 'email') {
		href = `mailto:${link.email}` || '#';
	}
	if (link.linktype === 'asset') {
		href = link.url || link.cached_url || '#';
	}
	if (link.linktype === 'story') {
		href = normalizeFullSlug(link.url || link.cached_url || '#') ?? '#';
	}
	if (link.anchor) {
		const url = normalizeFullSlug(link.url || link.cached_url || '#');
		href = `${url}#${link.anchor}`;
	}

	return { href: href.replace(/\s/g, '') };
};

interface BlokProps {
	blok: LinkButtonStoryblok;
	meta?: {
		variant?: string;
		size?: 'small' | 'medium' | 'large';
	};
}

const blokProps = ({ blok, meta }: BlokProps): LinkButtonProps => {
	const linkProps = buildLinkProps(blok.link);
	const isAssetLink = blok.link.linktype === 'asset';
	const isUrlLink = blok.link.linktype === 'url';

	const props: LinkButtonProps = {
		...linkProps,
		type: blok.link.linktype ?? 'url',
		children: blok.text,
		target: blok.link?.target ?? '_self',
		_uid: blok._uid,
	};
	if (meta) {
		const { variant, size } = meta;
		Object.assign(props, { variant, size });
	}
	if (isAssetLink || isUrlLink) {
		props.target = '_blank';
	}
	return props;
};

export interface LinkButtonProps {
	href: string;
	variant?: ButtonVariant;
	size?: 'small' | 'medium' | 'large';
	className?: string;
	target?: '_self' | '_blank';
	rel?: string;
	testID?: string;
	title?: string;
	arrow?: boolean;
	type?: LinkButtonStoryblok['link']['linktype'];
	children: React.ReactNode;
	onClick?: () => void;
	icon?: ReactElement;
	block?: boolean;
	_uid?: string;

	/**
	 * addContrast is used to add contrast to elements placed on an image or video
	 */
	addContrast?: boolean;
	queryString?: string;
	tabIndex?: number;
}

/**
 * A Link that looks like a button and has support for icons and theming
 */
export const LinkButton: Blok<LinkButtonProps, BlokProps> = ({
	children,
	className,
	href,
	variant = 'filled',
	size = 'medium',
	target,
	testID,
	title,
	arrow = true,
	type,
	onClick,
	icon,
	addContrast = false,
	queryString,
	block,
	tabIndex,
	...rest
}) => {
	const addHoverEffect = variant === 'text' || variant === 'marklink' || variant === 'link';
	let { rel } = rest;

	if (rel == null && target === '_blank') {
		rel = 'noopener noreferrer';
	}

	if (type == 'asset' || icon) {
		arrow = false;
	}

	if (type == 'email') {
		href = `mailto:${href}`;
	}

	if (queryString) {
		const hrefHasQuerystring = /\?/.test(href);
		const queryPrefix = hrefHasQuerystring ? '&' : '?';
		href = `${href}${queryPrefix}${queryString}`;
	}

	const sharedProps = {
		href,
		target,
		rel,
		onClick,
		'data-testid': testID,
		title,
	};

	return (
		<LinkAnchor {...sharedProps} hoverEffect={addHoverEffect} tabIndex={tabIndex}>
			<StyledButton
				addContrast={addContrast}
				className={className}
				variant={variant}
				size={size}
				block={block}
				arrow={arrow}
			>
				{children} {icon}
			</StyledButton>
		</LinkAnchor>
	);
};

LinkButton.blokProps = blokProps;
