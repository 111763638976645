import { Heading } from '@/atoms';
import { TextWrapImageStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { cls } from '@/utils';
import { renderRichText } from '../../RichText';
import styles from './TextWrapImage.module.scss';

interface Props {
	blok: TextWrapImageStoryblok;
}

export const TextWrapImage: React.FC<Props> = ({ blok }) => {
	const { text, image, imagePlacement, title, titleSize } = blok;

	return (
		<div className={styles.container}>
			{title && <Heading title={title} as={titleSize ?? 'h2'} exo2 articleSize />}
			<div data-testid="text-wrap-image-content">
				<div className={cls(styles.image, styles[`float--${imagePlacement}`])}>
					{image?.filename && <ImageAsset asset={image} objectFit="contain" testID="image" />}
				</div>
				{renderRichText(text, { exo2: true, layout: 'article' })}
			</div>
		</div>
	);
};
