import React, { createContext, useContext } from 'react';
import { Integration, IntegrationClientType, Integrator } from './types';

/**
 * An Integration from App Market
 */
export const useIntegrationCurrent = () => {
	return useContext(Context);
};

interface ContextProps {
	integration: Integration;
	integrator: Integrator;
}
const Context = createContext<ContextProps>({
	integration: {
		appId: '',
		redirectUri: '',
		appName: '',
		summaryTitle: '',
		tenantId: '',
		summary: '',
		about: '',
		howTo: '',
		readMoreUrl: '',
		priceModel: { clientType: IntegrationClientType.BuyableApp, price: '' },
		urlSlug: '',
		images: [],
		scopes: null,
		categories: [],
		ratings: {
			averageScore: 0,
			numberOfRatings: 0,
		},
		contactInformation: {
			companyName: '',
			email: '',
			phone: '',
		},
		uniqueSellingPoints: {
			title: '',
			points: [],
		},
		customerReviews: [{ companyName: '', review: '', role: '' }],
		insights: {
			numberOfUsersRange: '',
		},
		tags: [],
	},
	integrator: {
		tenantId: '',
		companyName: '',
		about: '',
		successStory: '',
		contactEmail: '',
		contactPhone: '',
		contactHomepage: '',
		companyLogoKey: '',
		companyLogoUrl: '',
		companyNameSlug: '',
	},
});

interface Props {
	children: React.ReactNode;
	integration: Integration;
	integrator: Integrator;
}

export const IntegrationCurrentProvider: React.FC<Props> = ({ children, integration, integrator }) => {
	const value = {
		integration,
		integrator,
	};
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
