import { BrandLine, ContainerFluid, Divider, Heading, Section, Tags, Text } from '@/atoms';
import SocialMedia from '@/bloks/Header/HeaderArticle/SocialMedia';
import { renderRichText } from '@/bloks/RichText';
import { ArticlePageStoryblok } from '@/components';
import { ImageAsset } from '@/metabloks';
import { useDateString } from '@/utils';
import { usePathname } from 'next/navigation';
import * as React from 'react';
import { t } from 'ttag';
import styles from './ArticlePage.module.scss';

export interface ArticlePageProps {
	blok: ArticlePageStoryblok;
	meta: Record<string, any>;
}

export const ArticlePage = ({ blok, meta }: ArticlePageProps) => {
	const { title, image, intro, longText } = blok;
	const { published_at } = meta;
	const tags = [blok.categoryTag ?? '', blok.labelTag ?? ''];
	const pathname = usePathname();

	return (
		<ContainerFluid theme="white">
			<Section as="article" maxWidth="832px" paddingY={{ base: 'md', md: '3xl' }} paddingX={{ base: 'md', lg: 'none' }}>
				<header>
					<BrandLine />
					<div className={styles.tagContainer}>
						<Tags className={styles.tags} tags={tags} />
						{pathname && <SocialMedia href={pathname} title={''} background />}
					</div>

					<Heading as="h1" size="h2" title={title} marginBottom="xl" exo2 />
					<Text className={styles.published} muted>
						{t`Senast uppdaterad` + ': ' + useDateString(published_at)}
					</Text>
					{image?.filename && (
						<div className={styles.image}>
							<ImageAsset absolute priority asset={image} objectFit="cover" fill objectPosition="center" />
						</div>
					)}
				</header>
				<div className={styles.textWrapper}>
					{intro && (
						<Text bold as="div" marginBottom="xl">
							{renderRichText(intro)}
						</Text>
					)}
					{longText && renderRichText(longText, { exo2: true, layout: 'article' })}
				</div>
				<Divider margin="md" />
				<div className={styles.socialMediaFooter}>
					<Text bold marginBottom="none">{t`Dela den här artikeln`}</Text>
					{pathname && <SocialMedia href={pathname} title={''} />}
				</div>
				<Divider margin="md" />
			</Section>
		</ContainerFluid>
	);
};
