import { ContainerFluid, Heading, Section, Text } from '@/atoms';
import { Video } from '@/bloks/Other';
import { SectionVideoSmallStoryblok } from '@/components';
import { cls } from '@/utils';
import React, { ReactNode } from 'react';
import styles from './SectionVideoSmall.module.scss';

interface BlokProps {
	blok: SectionVideoSmallStoryblok;
	meta?: {
		removeMarginBottom: boolean;
		exo2?: boolean;
		layout?: 'article';
	};
}

interface SectionVideoSmallProps {
	title?: string;
	text?: string;
	videos: ReactNode;
	removeMarginBottom?: boolean;
	exo2?: boolean;
	layout?: 'article';
}

const blokProps = ({ blok, meta }: BlokProps): SectionVideoSmallProps => ({
	title: blok?.title,
	text: blok?.text,
	videos: blok.video && blok.video.map((video) => <Video key={video?._uid} {...Video.blokProps({ blok: video })} />),
	removeMarginBottom: meta?.removeMarginBottom,
	exo2: meta?.exo2,
	layout: meta?.layout,
});

export const SectionVideoSmall = ({
	title,
	videos,
	text,
	removeMarginBottom = false,
	exo2,
	layout,
}: SectionVideoSmallProps) => {
	return (
		<ContainerFluid
			marginBottom={removeMarginBottom ? 'none' : '3xl'}
			className={cls(layout === 'article' && styles.marginTop)}
		>
			<Section padding={{ base: 'none' }}>
				{title && <Heading as="h2" size="h2" title={title} exo2={exo2} articleSize={layout === 'article'} />}
				{text && <Text>{text}</Text>}
				{videos}
			</Section>
		</ContainerFluid>
	);
};

SectionVideoSmall.blokProps = blokProps;
