import { Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import {
	ArticleMiniGroupStoryblok,
	ArticlePageStoryblok,
	BlogPostStoryblok,
	CompanyGuidePostStoryblok,
} from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { StoryblokStory } from 'storyblok-generate-ts';
import { ArticleMini, ArticleMiniProps } from '../../../metabloks/ArticleMini/ArticleMini';
import styles from './ArticleMiniGroup.module.scss';

interface BlokProps {
	blok: ArticleMiniGroupStoryblok;
}

const convertArticle = (
	article:
		| StoryblokStory<ArticlePageStoryblok>
		| StoryblokStory<BlogPostStoryblok>
		| StoryblokStory<CompanyGuidePostStoryblok>,
): ArticleMiniProps | null => {
	const articleContent = article.content;

	if (!articleContent) {
		return null;
	}

	const cardTitle = articleContent?.cardTitle;
	const cardImage = articleContent?.cardImage;

	const categoryTag = 'categoryTag' in articleContent ? articleContent.categoryTag : '';
	const headerContent = 'header' in articleContent ? articleContent.header?.[0] : null;

	if (articleContent.component === 'ArticlePage') {
		return {
			title: cardTitle ?? articleContent.title,
			category: categoryTag,
			image: cardImage?.filename ? cardImage : articleContent.image,
			_uid: articleContent._uid,
			fullSlug: article.full_slug,
		};
	}

	return {
		title: cardTitle || headerContent?.h1Part1 || '',
		category: categoryTag,
		image: cardImage?.filename ? cardImage : headerContent?.image,
		fullSlug: article.full_slug,
		_uid: articleContent._uid,
	};
};

const blokProps = ({ blok }: BlokProps): ArticleMiniGroupProps => {
	const articles = blok.articles as unknown as (
		| StoryblokStory<ArticlePageStoryblok>
		| StoryblokStory<BlogPostStoryblok>
		| StoryblokStory<CompanyGuidePostStoryblok>
	)[];

	return {
		title: blok.title,
		articles: articles.map((article) => convertArticle(article)).filter(Boolean) as ArticleMiniProps[],
		_uid: blok._uid,
		_editable: blok._editable,
		component: 'ArticleMiniGroup',
	};
};

export interface ArticleMiniGroupProps {
	title: string;
	articles?: ArticleMiniProps[];
	_uid: string;
	_editable?: string;
	component: 'ArticleMiniGroup';
}

export const ArticleMiniGroup: Blok<ArticleMiniGroupProps, BlokProps> = ({ title, articles, _editable }) => {
	const themeInfo = getThemeInfo('darkGreen');
	return (
		<div {...editableBlok({ _editable })}>
			<ThemeProvider theme="darkGreen">
				<div className={cls(styles.titleWrapper, themeInfo.styles.bgColor)}>
					<Text marginBottom="none" size="ingress">
						{title}
					</Text>
				</div>
			</ThemeProvider>
			<div className={styles.articlesWrapper}>
				{articles?.map((article) => <ArticleMini key={article._uid} {...article} />)}
			</div>
		</div>
	);
};

ArticleMiniGroup.blokProps = blokProps;
