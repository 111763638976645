import { ContainerFluid, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { CardArticleGroup } from '@/bloks/Card';
import { CardArticleGroupProps } from '@/bloks/Card/CardArticleGroup/CardArticleGroup';
import { SectionArticleStoryblok } from '@/components';
import styles from './SectionArticle.module.scss';

interface BlokProps {
	blok: SectionArticleStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	cardGroups: blok.cardGroups.map((cardGroup) => CardArticleGroup.blokProps({ blok: cardGroup })),
	_editable: blok._editable,
});

interface Props {
	cardGroups: CardArticleGroupProps[];
	_editable?: string;
}

export const SectionArticle: Blok<Props, BlokProps> = ({ cardGroups }) => {
	return (
		<ContainerFluid>
			<Section>
				{cardGroups.map((group, index) => (
					<CardArticleGroup className={styles.cardGroup} key={`cardGroup-${index}`} {...group} />
				))}
			</Section>
		</ContainerFluid>
	);
};

SectionArticle.blokProps = blokProps;
