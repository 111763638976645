import { LinkButton } from '@/bloks/Button';
import { PriceListItemStoryblok, ProductCatalogItemStoryblok, ProductCatalogPackageItemStoryblok } from '@/components';
import { useProductPrice } from '@/contexts/products';
import { ISbStoryData } from '@/types/storyblok';
import { PriceListItemCustom } from '../PriceListItemCustom';

interface Props {
	blok: PriceListItemStoryblok;
	meta: {
		subTitle1?: string;
		subTitle2?: string;
		hideMonthlyPrice?: boolean;
	};
}

export function PriceListItem({ blok, meta }: Props) {
	const product = blok.product as unknown as ISbStoryData<
		ProductCatalogItemStoryblok | ProductCatalogPackageItemStoryblok
	>;
	const montlyPrice = useProductPrice(product, 3);
	const yearlyPrice = useProductPrice(product, 12);
	const linkButton = product.content?.readMoreButton?.[0];
	const linkButtonComponent = linkButton && { ...LinkButton.blokProps({ blok: linkButton }) };
	const additionalInfo = product.content?.additionalInfo;
	const priceUnit = (product.content as { priceUnit?: string }).priceUnit;
	const priceInterval = (product.content as { priceInterval?: string }).priceInterval;

	return (
		<PriceListItemCustom
			productName={product.name}
			montlyPrice={montlyPrice}
			yearlyPrice={yearlyPrice}
			linkButton={linkButtonComponent}
			additionalInfo={additionalInfo}
			subTitle1={meta?.subTitle1}
			subTitle2={meta?.subTitle2}
			priceUnit={priceUnit || 'kr'}
			priceInterval={priceInterval || 'mån'}
			showNewPriceChip={blok.showNewPriceChip}
			hideMonthlyPrice={meta?.hideMonthlyPrice}
			_editable={blok._editable}
		/>
	);
}
