import headingStyles from '@/atoms/Heading/Heading.module.scss';
import { PriceStructure } from '@/contexts/products';
import { useTheme } from '@/contexts/theme';
import cls from 'classnames';
import { t } from 'ttag';
import styles from './Price.module.scss';

const formatPrice = (price: number) => (Number.isInteger(price) ? price.toLocaleString('sv-SE') : price.toFixed(2));

type PriceProps = {
	className?: string;
	priceStructure: PriceStructure;
	unit?: string;
	interval?: string;
	firstPriceText?: string;
	secondPriceText?: string;
	size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'custom';
	showFrom?: boolean;
	crossedOutPrice?: boolean;
	priceText?: string;
};

export const Price: React.FC<PriceProps> = ({
	priceStructure,
	firstPriceText,
	secondPriceText,
	size = 'h2',
	showFrom = true,
	crossedOutPrice = false,
	priceText,
	...rest
}) => {
	const themeInfo = useTheme();

	if (!priceStructure) {
		return null;
	}

	return (
		<div className={styles.container} {...rest}>
			{firstPriceText && (
				<div className={cls(headingStyles['size--h6'], themeInfo.styles.textColor)}>{firstPriceText}</div>
			)}
			<div className={styles.priceContainer}>
				<div className={styles.price}>
					<div
						className={cls(
							styles.amount,
							headingStyles[`size--${size}`],
							themeInfo.styles.textColor,
							crossedOutPrice && styles.crossedOutPrice,
						)}
					>
						{showFrom && <span className={cls(styles.from, themeInfo.styles.textColor)}>{t`Från`}</span>}
						{formatPrice(priceStructure[12][0])}
					</div>
					<span className={cls(themeInfo.styles.textColor, styles.priceText)}>{priceText}</span>
				</div>
			</div>
		</div>
	);
};
