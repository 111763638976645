import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import styles from './BulletList.module.scss';

interface Props {
	children: React.ReactNode;
}

export const BulletList: React.FC<Props> = ({ children }) => {
	const themeInfo = useTheme();

	return <ul className={cls(styles.list, themeInfo.styles.iconColor)}>{children}</ul>;
};
