import { Icon, Text, TextField } from '@/atoms';
import { getTheme } from '@/bloks/Page/NavigationPageEducation/CardEducation/EducationTypeTheme';
import { Item, ItemFilter, filterAnyMatch } from '@/bloks/Page/NavigationPageEducation/EducationFilter/ItemFilter';
import { SearchField } from '@/bloks/Page/NavigationPageEducation/EducationFilter/SearchField';
import { CardEducationStoryblok } from '@/components';
import { CustomFields, EducationType } from '@/contexts/eduadmin';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { t } from 'ttag';
import styles from './FilterContainer.module.scss';

interface Props {
	educationCards: CardEducationStoryblok[];
	subjects?: CustomFields | null;
	targetGroups?: CustomFields | null;
	educationTypes?: CustomFields | null;
	setFilteredCards: (courses: CardEducationStoryblok[]) => void;
	selectedFilters: Record<string, Item[]>;
	setSelectedFilters: (filters: Record<string, Item[]>) => void;
}

export enum QueryParams {
	TARGET_GROUP = 'targetGroup',
	SUBJECT = 'subject',
	EDUCATION_TYPE = 'educationType',
	PRICE = 'price',
}

const getDefinedFields = (customFields: CustomFields | null | undefined) => {
	return (
		(customFields?.value.flatMap((field) =>
			field.CustomFieldAlternatives.flatMap((value) => value.CustomFieldAlternativeValue),
		) as string[]) || []
	);
};

export const FilterContainer: React.FC<Props> = ({
	educationCards,
	subjects,
	targetGroups,
	educationTypes,
	setFilteredCards,
	selectedFilters,
	setSelectedFilters,
}) => {
	const [showFilters, setShowFilters] = useState(false);
	const [searchFilter, setSearchFilter] = useState('');
	const educationCardsRef = useRef(educationCards);
	const actualSubjects = educationCardsRef.current?.flatMap((card) => card.subjects) || [];
	const actualTargetGroups = educationCardsRef.current?.flatMap((card) => card.targetGroups) || [];

	const router = useRouter();

	const targetGroupItems = getDefinedFields(targetGroups)
		.filter((value) => actualTargetGroups.includes(value))
		.map((name) => ({
			name: name,
			themeInfo: undefined,
		}));

	const subjectItems = getDefinedFields(subjects)
		.filter((value) => actualSubjects.includes(value))
		.map((name) => ({
			name: name,
			themeInfo: undefined,
		}));

	const educationTypeItems = getDefinedFields(educationTypes).map((name) => ({
		name: name,
		themeInfo: getTheme(name as EducationType),
	}));

	const priceItems = [{ name: 'Kostnadsfri', themeInfo: undefined }];

	const getSelectedItemsFromQuery = (queryParam: string, items: Item[]): Item[] => {
		const value = new URLSearchParams(`?${router.asPath.split('?')[1]}`).get(queryParam) ?? undefined;
		const values = value?.replace(/[^a-zA-Z0-9-_&,; åäöÅÄÖ]/g, '')?.split(';');
		return values ? items.filter((item) => values.some((q) => q.toLowerCase() === item.name.toLowerCase())) : [];
	};

	const filterText = (card: CardEducationStoryblok, searchText: string): boolean => {
		const lowerCaseSearchText = searchText.toLowerCase();
		return (
			card.title.toLowerCase().includes(lowerCaseSearchText) ||
			card.text.toLowerCase().includes(lowerCaseSearchText) ||
			card.educationType.toLowerCase().includes(lowerCaseSearchText) ||
			(card.subjects || []).some((subject) => subject.toLowerCase().includes(lowerCaseSearchText)) ||
			(card.targetGroups || []).some((targetGroup) => targetGroup.toLowerCase().includes(lowerCaseSearchText))
		);
	};
	useEffect(() => {
		setSelectedFilters({
			[QueryParams.TARGET_GROUP]: getSelectedItemsFromQuery(QueryParams.TARGET_GROUP, targetGroupItems),
			[QueryParams.SUBJECT]: getSelectedItemsFromQuery(QueryParams.SUBJECT, subjectItems),
			[QueryParams.EDUCATION_TYPE]: getSelectedItemsFromQuery(QueryParams.EDUCATION_TYPE, educationTypeItems),
			[QueryParams.PRICE]: getSelectedItemsFromQuery(QueryParams.PRICE, priceItems),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.asPath]);

	useEffect(() => {
		const filteredCards = educationCardsRef.current.filter((card) => {
			return (
				filterAnyMatch(selectedFilters[QueryParams.EDUCATION_TYPE], card.educationType || '') &&
				filterAnyMatch(selectedFilters[QueryParams.SUBJECT], (card.subjects || []).join(' ')) &&
				filterAnyMatch(selectedFilters[QueryParams.TARGET_GROUP], (card.targetGroups || []).join(' ')) &&
				filterAnyMatch(selectedFilters[QueryParams.PRICE], card.price === 'Kostnadsfri' ? 'Kostnadsfri' : '-') &&
				filterText(card, searchFilter)
			);
		});
		setFilteredCards(filteredCards);
	}, [selectedFilters, setFilteredCards, searchFilter]);

	return (
		<div className={styles.filterContainer}>
			<div onClick={() => setShowFilters(!showFilters)} className={styles.filterHeader}>
				{t`Filtrera utbildningar`}
				<Icon name={showFilters ? 'fa-close' : 'fa-filter'} />
			</div>
			<div className={cls(styles.filterContent, { [styles.show]: showFilters })}>
				<div className={styles.searchFieldWrapper}>
					<SearchField value={searchFilter} setValue={setSearchFilter} />
				</div>
				<ItemFilter
					items={targetGroupItems}
					selectedFilters={selectedFilters[QueryParams.TARGET_GROUP]}
					queryParam={QueryParams.TARGET_GROUP}
					label={t`Målgrupp`}
				/>
				<ItemFilter
					items={subjectItems}
					selectedFilters={selectedFilters[QueryParams.SUBJECT]}
					queryParam={QueryParams.SUBJECT}
					label={t`Ämne`}
				/>
				<ItemFilter
					items={educationTypeItems}
					selectedFilters={selectedFilters[QueryParams.EDUCATION_TYPE]}
					queryParam={QueryParams.EDUCATION_TYPE}
					label={t`Utbildningstyp`}
				/>
				<ItemFilter
					items={priceItems}
					selectedFilters={selectedFilters[QueryParams.PRICE]}
					queryParam={QueryParams.PRICE}
					label={t`Kostnad`}
				/>
			</div>
		</div>
	);
};
