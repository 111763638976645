import { EducationType } from '@/contexts/eduadmin';
import { getThemeInfo } from '@/themes';

export const getTheme = (educationType?: EducationType) => {
	if (educationType === EducationType.Recorded) {
		return getThemeInfo('green');
	}
	if (educationType === EducationType.Customized) {
		return getThemeInfo('purple');
	}
	if (educationType === EducationType.Remote) {
		return getThemeInfo('liveGreen');
	}
	if (educationType === EducationType.InPlace) {
		return getThemeInfo('darkGreen');
	}
	if (educationType === EducationType.Package) {
		return getThemeInfo('yellow');
	}
	return getThemeInfo('darkText');
};
