import { ThemeContrastStoryblok } from '@/components';
import { FullThemeName, ThemeInfo, ThemeName, getChildTheme, getThemeInfo } from '@/themes';
import { createContext, useContext } from 'react';

const defaultTheme: ThemeName = 'white';
const defaultContrast: ThemeName = 'lightText';

interface Props {
	children: React.ReactNode;
	theme?: ThemeName;
	contrast?: ThemeContrastStoryblok['theme'];
	applyChildTheme?: boolean;
}

interface ContextProps {
	theme: ThemeName;
	contrast?: ThemeContrastStoryblok['theme'];
	applyChildTheme?: boolean;
}

const Context = createContext<ContextProps>({ theme: defaultTheme, contrast: defaultContrast });

export const useThemeContext = () => useContext(Context);

export const useTheme = (): ThemeInfo => {
	const { applyChildTheme, theme } = useContext(Context);
	return applyChildTheme ? getThemeInfo(getChildTheme(theme as FullThemeName)) : getThemeInfo(theme);
};

export const useContrast = (): ThemeInfo | undefined => {
	const { contrast } = useContext(Context);
	return getThemeInfo(contrast);
};

export const ThemeProvider: React.FC<Props> = ({
	children,
	theme = defaultTheme,
	contrast = defaultContrast,
	applyChildTheme = false,
}) => {
	const value = {
		theme,
		contrast,
		applyChildTheme,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
