import { UpsalesNewsFormStoryblok } from '@/components';
import { useServerPageConfig } from '@/contexts/config';
import { ISbStoryData } from '@/types/storyblok';
import { t } from 'ttag';
import { UpsalesField, UpsalesForm } from '../UpsalesForm';

interface Props {
	story: ISbStoryData<UpsalesNewsFormStoryblok>;
}

/**
 * UpsalesNewsForm is meant to be used in CardFiveUpsalesForm, since Storyblok can only handle two levels of nesting for resolving relations.
 * This is the reason UpsalesFields are defined programmatically for this form.
 */
export const UpsalesNewsForm = ({ story }: Props) => {
	const { externalDeployment } = useServerPageConfig();
	const customIsConsultantField = externalDeployment ? 'Contact.custom_45' : 'Contact.custom_24';

	const inputFields: UpsalesField[] = [
		{
			name: 'Contact.email',
			placeholder: t`E-post`,
			required: true,
		},
		{
			name: customIsConsultantField,
			placeholder: t`Jobbar som redovisningskonsult`,
			required: false,
			checkbox: true,
		},
	];

	if (!story?.content?.formId) {
		return null;
	}

	return (
		<UpsalesForm
			formId={story?.content?.formId}
			feedbackTitle={story?.content?.feedbackTitle ?? ''}
			feedbackText={story?.content?.feedbackText ?? ''}
			inputFields={inputFields}
			formName={story?.slug}
		/>
	);
};
