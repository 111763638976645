import { Checkbox, Grid, Icon, Text } from '@/atoms';
import { updateRoute } from '@/bloks/Page/NavigationPageEducation/EducationFilter/updateRoute';
import { ThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { t } from 'ttag';
import styles from './ItemFilter.module.scss';

export interface Item {
	name: string;
	themeInfo?: ThemeInfo;
}

interface Props {
	items: Item[];
	selectedFilters: Item[];
	queryParam: string;
	label: string;
	showMoreLimit?: number;
}

export const filterAnyMatch = (selectedItems: Item[], text: string) => {
	if (selectedItems.length === 0) {
		return true;
	}
	return selectedItems.some((choice) => {
		return text.toLowerCase().includes(choice.name.toLowerCase());
	});
};

export const ItemFilter: React.FC<Props> = ({ items, selectedFilters, queryParam, label, showMoreLimit = 6 }) => {
	const [showAll, setShowAll] = useState(false);
	const router = useRouter();

	const isChecked = (searchFilter: Item[], choice: Item) => {
		return searchFilter.filter((filter) => filter.name.toLowerCase() === choice.name.toLowerCase()).length > 0;
	};

	const itemsToShow = showAll ? items : items.slice(0, showMoreLimit);

	return (
		<div className={styles.itemFilter}>
			<Text className={styles.label}>{label}</Text>
			<div className={styles.allCheckbox}>
				<Checkbox
					bordered={true}
					checked={selectedFilters.length === 0}
					label={t`Alla`}
					onClick={() => {
						updateRoute('', queryParam, router);
					}}
				/>
			</div>

			<Grid columns={{ base: 1, lg: 1 }} colGap={'3xs'}>
				{itemsToShow.map((filter) => {
					return (
						<div key={filter.name} className={styles.checkboxContainer}>
							<Checkbox
								key={filter.name}
								bordered={true}
								checked={isChecked(selectedFilters, filter)}
								label={filter.name}
								onClick={() => {
									const filteredItems = selectedFilters.filter((selected) => selected.name !== filter.name);
									if (!isChecked(selectedFilters, filter)) {
										filteredItems.push(filter);
									}
									const value = filteredItems.map((item) => item.name).join(';');
									updateRoute(value, queryParam, router);
								}}
							/>
							{filter.themeInfo && <div className={cls(styles.box, filter.themeInfo.styles.bgColor)} />}
						</div>
					);
				})}
			</Grid>

			<div className={styles.showMoreContainer}>
				{items.length > showMoreLimit && (
					<div onClick={() => setShowAll(!showAll)} className={styles.showMoreButton}>
						{showAll ? t`Visa färre` : t`Visa fler`}
						<Icon name="chevron-down" size="1x" className={cls(styles.icon, { [styles.iconOpen]: showAll })} />
					</div>
				)}
			</div>
		</div>
	);
};
