import { CaptchaForm, Checkbox, FileUpload, Select, SelectOption, Text, TextField } from '@/atoms';
import { FieldValidationProvider, FormValidationProvider } from '@/atoms/CaptchaForm/FormValidationContext';
import { getCaptchaSolution } from '@/atoms/CaptchaForm/FriendlyCaptcha';
import { Blok } from '@/bloks/Blok';
import { ZendeskFormStoryblok } from '@/components';
import { useServerPageConfig } from '@/contexts/config';
import { useTheme } from '@/contexts/theme';
import { Env } from '@/server/config/types';
import { ZendeskFormField, ZendeskHandlerResponse } from '@/types';
import { ZendeskFormOption } from '@/types/zendesk';
import { slugifyName } from '@/utils';
import { fetchApi } from '@/utils/fetchApi';
import { validateEmailField } from '@/validation';
import * as Sentry from '@sentry/nextjs';
import Script from 'next/script';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import { LinkButton } from '../../Button';
import styles from './../Form.module.scss';
import { getDropZone, getEditableFields, getFieldType } from './utils';

interface BlokProps {
	blok: ZendeskFormStoryblok;
	meta?: {
		zone?: number | undefined;
		centerFeedbackText?: boolean;
	};
}

const blokProps = ({ blok, meta }: BlokProps): Props => ({
	formId: blok.formId,
	subject: blok.subject,
	groupId: blok.groupId,
	priority: blok.priority,
	feedbackText: blok.feedbackText,
	feedbackTitle: blok.feedbackTitle,
	sendSafely: Boolean(blok.sendSafely),
	attachmentsZendesk: Boolean(blok.attachmentsZendesk),
	tag: blok.tag ? blok.tag : '',
	zone: meta?.zone,
	centerFeedbackText: meta?.centerFeedbackText,
	_uid: blok._uid,
});

interface Props {
	formId: string;
	subject?: string;
	groupId: string;
	priority: ZendeskFormStoryblok['priority'];
	feedbackText?: string;
	feedbackTitle?: string;
	sendSafely: boolean;
	attachmentsZendesk: boolean;
	tag?: string;
	zone?: number | undefined;
	centerFeedbackText?: boolean;
	_uid?: string;
}

export const ZendeskForm: Blok<Props, BlokProps> = ({
	formId,
	groupId,
	priority,
	feedbackText,
	feedbackTitle,
	sendSafely,
	attachmentsZendesk,
	subject,
	tag,
	zone,
	centerFeedbackText,
	_uid,
}) => {
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [formSubject, setFormSubject] = useState<string>(subject ?? '');
	const [description, setDescription] = useState<string>('');
	const [formSent, setFormSent] = useState(false);
	const [form, setForm] = useState<ZendeskHandlerResponse | null>(null);
	const [editableFields, setEditableFields] = useState<ZendeskFormField[]>([]);
	const formRef = useRef<HTMLDivElement | null>(null);
	const [customFormFields, setCustomFormFields] = useState<any[]>([]);
	const [customSelectFields, setCustomSelectFields] = useState<any[]>([]);
	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
	const [companyName, setCompanyName] = useState('');

	const themeInfo = useTheme();
	const theme = themeInfo.name;

	const { env } = useServerPageConfig();

	const isProd = env === Env.Prod || env === Env.Acce; // acce-website uses zendesk prod env
	const isConversionForm = isProd ? formId == '27822' : formId == '5212172432786';
	const companyNameFieldId = isProd ? 5423198944658 : 7668400942866;

	// SendSafely
	const widget: React.MutableRefObject<any> = useRef<any | null>(null);
	const [activateJquery, setActivateJquery] = useState(false);
	const [activateSendsafely, setActivateSendsafely] = useState(false);
	const [widgetInitialized, setWidgetInitialized] = useState(false);

	const config = useServerPageConfig();
	const locale = config.locale ?? 'sv';

	const getForm = async (formId: string, locale: string) => {
		const result = await fetchApi(`zendesk-v1/[...formId] GET`, formId, locale);
		if (result.body?.data) {
			setForm(result.body.data);
		}
	};

	useEffect(() => {
		if (!form && formId) {
			getForm(formId, locale).catch((error) => {
				Sentry.captureException(error);
			});
			return;
		}
		if (form && form.id) {
			setEditableFields(getEditableFields(form.fields));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	const updateFormData = () => {
		const fields: any[] = [];
		const formElement = formRef.current?.querySelector(`#ZENDESK-${_uid}`) as unknown as HTMLFormElement;
		const formData = new FormData(formElement);

		formData.forEach((value, prop) => {
			const exclude = ['description', 'subject', 'frc-captcha-solution', 'firstName', 'lastName', 'email'];
			if (!exclude.includes(prop)) {
				fields.push({ id: prop, value });
			}

			if (prop === companyNameFieldId.toString()) {
				setCompanyName(value as string);
			}
		});
		setCustomFormFields(fields);
	};

	const handleAttachments = (files: File[]) => {
		setFilesToUpload(files);
	};

	const handleSubmit = (e: React.BaseSyntheticEvent, honeypotTriggered?: boolean) => {
		return async function postZendeskForm() {
			const data = {
				customFormFields: customFormFields.concat(customSelectFields),
				groupId,
				priority,
				subject: isConversionForm ? `${formSubject} - ${companyName}` : formSubject,
				firstName,
				lastName,
				email,
				formId,
				formTag: tag ?? '',
				description,
				attachments: filesToUpload,
			};

			const body = {
				data,
				captchaSolution: getCaptchaSolution(e),
			};

			if (sendSafely && widgetInitialized && activateJquery && activateSendsafely) {
				await new Promise<void>((resolve) => {
					widget.current.finalizePackage((url: string) => {
						data.description = data.description.concat('\nFiler för konvertering: ', url);
						if (!url) {
							throw new Error('missing.sendsafely.url');
						}
						resolve();
					});
				});
			}
			if (honeypotTriggered) {
				throw Error('honeypot.triggered.zendesk');
			} else {
				await fetchApi('zendesk-v1/[...formId] POST', body);
			}
			setFormSent(true);
		};
	};

	useEffect(() => {
		if (sendSafely && activateJquery && activateSendsafely && !widget.current) {
			const placeholderElement = $(`#drop-zone-container-${formId}`);
			const formField = $(`#hidden-secure-link-field-${formId}`);

			widget.current = new (global.window as any).SendSafelyDropzone(getDropZone(), placeholderElement, formField);

			widget.current.FILES_NOT_DONE_WARNING = t`Vänligen vänta tills alla filer har laddats upp.`;
			widget.current.FILE_COMPLETED_TEXT = t`Filen uppladdad.`;
			widget.current.DROPZONE_TEXT = t`Släpp filer här eller klicka för att ladda upp`;
			widget.current.url = 'https://fortnox.sendsafely.co.uk';
			widget.current.disableAutoSubmit = true;
			widget.current.initialize();

			setWidgetInitialized(true);
		}
	}, [activateJquery, activateSendsafely, formId, sendSafely]);

	return (
		<>
			{sendSafely && (
				<>
					<Script
						onLoad={() => {
							setActivateJquery(true);
						}}
						src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
					/>
					<Script
						onLoad={() => {
							setActivateSendsafely(true);
						}}
						type="text/javascript"
						src="https://app.sendsafely.co.uk/js/external/SendSafelyDropzone.min.js"
					/>
				</>
			)}

			<div className={styles.form} ref={formRef}>
				<FormValidationProvider>
					<CaptchaForm
						onSubmit={handleSubmit}
						formId={`ZENDESK-${_uid}`}
						feedbackTitle={feedbackTitle}
						feedbackText={feedbackText}
						meta={{ centerFeedbackText, type: 'zendesk', zone }}
					>
						<div className={styles.formContent}>
							<div className={styles.textFieldContainer}>
								<FieldValidationProvider id="zendesk-form-firstname">
									<TextField
										id={`firstName-${formId}`}
										title={t`Förnamn`}
										autoComplete="given-name"
										name="firstName"
										type="text"
										required={true}
										bordered={theme === 'white'}
										onChange={(e) => setFirstName(e.target.value)}
										value={firstName}
									/>
								</FieldValidationProvider>
							</div>
							<div className={styles.textFieldContainer}>
								<FieldValidationProvider id="zendesk-form-lastname">
									<TextField
										id={`lastName-${formId}`}
										title={t`Efternamn`}
										autoComplete="family-name"
										name="lastName"
										type="text"
										required={true}
										bordered={theme === 'white'}
										onChange={(e) => setLastName(e.target.value)}
										value={lastName}
									/>
								</FieldValidationProvider>
							</div>
							<div className={styles.textFieldContainer}>
								<FieldValidationProvider id="zendesk-form-mail">
									<TextField
										id={`email-${formId}`}
										title={t`E-post`}
										autoComplete="email"
										name="email"
										type="email"
										required={true}
										bordered={theme === 'white'}
										onChange={(e) => setEmail(e.target.value)}
										value={email}
										validators={validateEmailField}
									/>
								</FieldValidationProvider>
							</div>
							{editableFields?.map((field: ZendeskFormField, index: number) => {
								const type = getFieldType(field.type);
								return (
									<div className={styles.textFieldContainer} key={index}>
										<FieldValidationProvider id={`zendesk-form-${slugifyName(field.name)}`}>
											{field.type === 'subject' && (
												<TextField
													id={`subject-${formId}`}
													hidden={Boolean(subject)}
													title={t`Ämne`}
													name="subject"
													testID={`zendesk-field-${slugifyName(field.name)}`}
													type={subject ? 'hidden' : 'text'}
													required={field.required}
													bordered={theme === 'white'}
													onChange={(e) => {
														updateFormData();
														setFormSubject(e.target.value);
													}}
													tooltip={field.description}
												/>
											)}
											{field.type === 'description' && (
												<TextField
													id={`description-${formId}`}
													title={t`Meddelande`}
													name="description"
													testID={`zendesk-field-${slugifyName(field.name)}`}
													type="textarea"
													required={field.required}
													bordered={theme === 'white'}
													onChange={(e) => {
														updateFormData();
														setDescription(e.target.value);
													}}
													tooltip={field.description}
												/>
											)}

											{type === 'select' && !!field.options?.length && (
												<Select
													name={field.id.toString()}
													title={field.name}
													aria-label={field.name}
													className={styles.input}
													bordered={theme === 'white'}
													onSelect={(value) => {
														let mutatedCustomFormFields = customSelectFields;
														const id = field.id.toString();
														const newCustomField = { id, value };
														mutatedCustomFormFields = mutatedCustomFormFields.filter((mCFF) => mCFF.id !== id);
														mutatedCustomFormFields.push(newCustomField);
														setCustomSelectFields(mutatedCustomFormFields);
													}}
													data={field.options?.map(
														(option: ZendeskFormOption) =>
															({
																id: option.name,
																label: option.name,
																value: option.value,
															}) as SelectOption,
													)}
													id={field.id.toString()}
												/>
											)}
											{type !== 'select' && field.type !== 'description' && field.type !== 'subject' && (
												<TextField
													id={`zendesk-field-${slugifyName(field.name)}`}
													title={field.name}
													name={field.id.toString()}
													testID={`zendesk-field-${slugifyName(field.name)}`}
													type={type}
													required={field.required}
													pattern={field.regexp}
													bordered={theme === 'white'}
													onChange={() => {
														updateFormData();
													}}
													tooltip={field.description}
												/>
											)}
										</FieldValidationProvider>
									</div>
								);
							})}
							<>
								{sendSafely && !formSent && (
									<div className={styles.widget}>
										<TextField type="hidden" className={styles.hiddenField} id={`hidden-secure-link-field-${formId}`} />

										<div id={`drop-zone-container-${formId}`}></div>
									</div>
								)}
								{sendSafely && !formSent && !widget.current && (
									<div className={styles.widget}>
										<Text
											className={styles.warningMsg}
										>{t`Något gick snett när formuläret laddades in. Prova att ladda om sidan.`}</Text>
									</div>
								)}
							</>
							<div>
								{sendSafely && (
									<Checkbox
										required={true}
										bordered={true}
										label={
											<Text size="small" marginBottom="none">
												{t`Jag godkänner`}{' '}
												<LinkButton
													href="https://a.storyblok.com/f/134143/x/357c9d6690/fortnox_allmanna_villkor_for_infomrationsoverforing.pdf"
													variant="text"
													className={styles.consent}
													arrow={false}
													target="_blank"
												>
													{t`Fortnox allmänna villkor för informationsöverföring`}
												</LinkButton>
											</Text>
										}
									/>
								)}
							</div>
							{attachmentsZendesk && <FileUpload onUpload={handleAttachments} />}
						</div>
					</CaptchaForm>
				</FormValidationProvider>
			</div>
		</>
	);
};
ZendeskForm.blokProps = blokProps;
