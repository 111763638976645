import { Divider, Heading, LinkAnchor, Text } from '@/atoms';
import { useSearchV2 } from '@/contexts/search/AddSearchProviderV2';
import { htmlToMarkdownToRichtext, richTextToString } from '@/utils';
import { Hit } from 'addsearch-js-client';

interface SearchResultItemProps {
	hit: Hit;
	onClick: () => void;
}

export const SearchResultItem: React.FC<SearchResultItemProps> = ({ hit, onClick }) => {
	const { isSearchOpen } = useSearchV2();
	const href = hit.url.replace('https://www.fortnox.se', '');
	const title = hit.title.replace(' | Fortnox', '');
	const hightlight = hit.highlight ? `${richTextToString(htmlToMarkdownToRichtext(hit.highlight))}...` : null;

	return (
		<>
			<LinkAnchor href={href} onClick={onClick} tabIndex={isSearchOpen ? 0 : -1}>
				<Heading as="div" size="h5" title={title} />
				<Text marginBottom="none">{hightlight ?? hit.meta_description}</Text>
			</LinkAnchor>
			<Divider margin="xl" />
		</>
	);
};
