import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { ThemeInfo } from '@/themes';
import { StyledButton } from './StyledButton';

export type ButtonVariant = 'filled' | 'outlined' | 'text' | 'marklink' | 'link';

export function getVariantStyles(variant: ButtonVariant, themeInfo: ThemeInfo) {
	switch (variant) {
		case 'outlined':
			return themeInfo.styles.buttonOutline;
		case 'text':
			return themeInfo.styles.textLink;
		case 'marklink':
			return themeInfo.styles.textLink;
		case 'link':
			return themeInfo.styles.textLink;
		default:
			return themeInfo.styles.buttonFilled;
	}
}

interface Props {
	children: React.ReactNode;

	/**
	 * A function that will be invoked when the user clicks the component.
	 */
	onClick?: () => any;

	/**
	 * Sets whether or not the button is disabled.
	 */
	disabled?: boolean;

	/**
	 * Displays a loading icon inside the button if set to true.
	 */
	isLoading?: boolean;

	variant?: ButtonVariant;

	type?: 'button' | 'submit';

	size?: 'small' | 'medium' | 'large';

	className?: string;

	testID?: string;

	id?: string;

	/**
	 * addContrast is used to add contrast to elements placed on an image or video
	 */
	addContrast?: boolean;
	tabIndex?: number;
	block?: boolean;
}

export const Button: React.FC<Props> = ({
	children,
	onClick,
	disabled,
	isLoading = false,
	variant = 'filled',
	type = 'submit',
	size,
	className,
	testID,
	id,
	addContrast = false,
	tabIndex = 0,
	block = false,
}) => {
	const { setTargetInteraction } = useImpressionTracking();

	return (
		<button
			type={type}
			disabled={disabled}
			onClick={() => {
				if (onClick) onClick();
				setTargetInteraction({ contentTarget: (children as string) ?? '' });
			}}
			data-test-id={testID}
			id={id}
			style={{ display: 'inline-flex', border: 'none', background: 'none', padding: 0 }}
			tabIndex={tabIndex}
		>
			<StyledButton
				addContrast={addContrast}
				isLoading={isLoading}
				variant={variant}
				className={className}
				disabled={disabled}
				size={size}
				block={block}
			>
				{children}
			</StyledButton>
		</button>
	);
};
