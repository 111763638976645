import { Heading, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { CardAttractImageStoryblok } from '@/components';
import { ThemeProvider } from '@/contexts/theme';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './CardAttractImage.module.scss';

interface BlokProps {
	blok: CardAttractImageStoryblok;
}

const blokProps = ({ blok }: BlokProps): CardAttractImageProps => ({
	image: blok.image,
	title: blok.title,
	text: blok.text,
	linkButton: blok.linkButton?.[0] && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	_uid: blok._uid,
	_editable: blok._editable,
	component: 'CardAttractImage',
});

export interface CardAttractImageProps {
	image?: ImageAssetProps;
	title: string;
	text: string;
	linkButton?: LinkButtonProps;
	_uid: string;
	_editable?: string;
	component: 'CardAttractImage';
}

export const CardAttractImage: Blok<CardAttractImageProps, BlokProps> = ({
	image,
	title,
	text,
	linkButton,
	_editable,
}) => {
	const theme = 'lightBeige';
	const themeInfo = getThemeInfo(theme);

	return (
		<ThemeProvider theme={theme}>
			<div {...editableBlok({ _editable })} className={styles.container}>
				{image?.filename && (
					<div className={styles.image}>
						<ImageAsset asset={image} absolute />
					</div>
				)}
				<div className={cls(styles.textContent, themeInfo.styles.bgColor)}>
					<Heading as="h2" size="h4" title={title} marginBottom="xl" />
					<Text>{text}</Text>
					{linkButton?.href && <LinkButton {...linkButton} variant="text" />}
				</div>
			</div>
		</ThemeProvider>
	);
};

CardAttractImage.blokProps = blokProps;
