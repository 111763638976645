export const customIconNames = [
	'angles-right',
	'arpc',
	'customer-number',
	'customers',
	'home-darkgreen',
	'home-white',
	'net-sales',
	'operating-margin',
	'operating-profit',
	'rule-of-fortnox',
	'share-result',
	'tax-result',
];
