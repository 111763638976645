import { cls, slugifyName } from '@/utils';
import { useKeyPress } from 'hooks/useKeyPress';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import React, { useEffect, useId, useReducer, useRef, useState } from 'react';
import { t } from 'ttag';
import { IconButton } from '../Button';
import { FieldLabel } from '../TextField/FieldLabel';
import styles from './Select.module.scss';

interface SelectIconsProps {
	hasValue?: boolean;
	required?: boolean;
	disabled?: boolean;
	hideXMark?: boolean;
	clearSelection: (e?: React.MouseEvent<HTMLButtonElement>) => any;
}

const SelectIcons: React.FC<SelectIconsProps> = ({ hasValue = false, disabled = false, clearSelection, hideXMark }) => (
	<div className={styles.iconGroup}>
		{hasValue && !disabled && !hideXMark && (
			<IconButton
				iconName="xmark"
				aria-label={t`Rensa val`}
				className={cls(styles.icon, styles.filterClearIcon)}
				onClick={clearSelection}
				iconColor="--border-hovered-color"
				iconSize="1x"
			/>
		)}
		{
			<IconButton
				aria-label={t`Välj`}
				iconName="chevron-down"
				className={styles.icon}
				disabled={disabled}
				iconColor="--border-hovered-color"
			/>
		}
	</div>
);

export interface SelectOption {
	id: string;
	value: string;
	label: string;
}

interface OptionProps {
	option: SelectOption;
	isSelected?: boolean;
	onSelect?: (value: any) => void;
	focused?: boolean;
	size?: string;
	index: number;
}

const Option: React.FC<OptionProps> = ({ option, isSelected, onSelect, focused, size, index }) => {
	return (
		<button
			role="option"
			aria-selected={isSelected}
			className={cls(styles.option, {
				[styles.active]: isSelected,
				[styles.focused]: focused,
				[styles.small]: size === 'small',
			})}
			onClick={onSelect}
			value={option.value}
			data-index={index}
		>
			{option.label}
		</button>
	);
};

function reducer(state: { index: number }, action: any): { index: number } {
	const data = action.data;
	switch (action.type) {
		case 'KEY_DOWN':
			if (state.index < data.length - 1) {
				return {
					...state,
					index: state.index + 1,
				};
			}
			return { index: 0 };

		case 'KEY_UP':
			if (state.index > 0) {
				return {
					...state,
					index: state.index - 1,
				};
			}
			return { index: data.length - 1 };
		case 'CLEAR_INDEX':
			return { index: -1 };
		default:
			return state;
	}
}

const defaultFilter = (data: SelectOption[], searchTerm: string) => {
	return data.filter((option) => {
		if (option.value.toLowerCase().includes(searchTerm?.toLowerCase())) {
			return option;
		}
	});
};

interface Props {
	data: SelectOption[];
	title: string;
	required?: boolean;
	disabled?: boolean;
	invalid?: boolean;
	helperText?: string;
	defaultValue?: SelectOption;
	selected?: SelectOption | null;
	searchable?: boolean;
	onSelect?: (value: string) => void;
	onClear?: () => void;
	filterFunction?: () => any;
	autoFocus?: boolean;
	size?: 'regular' | 'small';
	testID?: string;
	className?: string;
	name?: string;
	id: string;
	/**
	 * A translated short label describing what a click on this button does.
	 * Shown to screen readers and useful for end-to-end tests as well.
	 */
	'aria-label': string;
	hideXMark?: boolean;
	bordered?: boolean;
}

interface SelectState {
	isActive: boolean;
	isFocused: boolean;
	selectedValue?: string;
	inputValue?: string;
	displayLabel?: string;
	result: SelectOption[];
	cursor?: number;
}

export const Select: React.FC<Props> = ({
	data,
	title,
	required,
	disabled = false,
	invalid = false,
	helperText,
	defaultValue,
	selected,
	searchable = false,
	onSelect,
	onClear,
	filterFunction = defaultFilter,
	autoFocus = false,
	size = 'regular',
	testID,
	className,
	name,
	id,
	'aria-label': ariaLabel,
	hideXMark = false,
	bordered = true,
}) => {
	let inputId = useId();
	if (id) inputId = id;
	const labelId = `${inputId}_label`;
	const listboxId = `${inputId}_list`;
	const [state, dispatch] = useReducer(reducer, { index: -1 });

	const [selectState, setSelectState] = useState<SelectState>({
		isActive: false,
		isFocused: false,
		displayLabel: defaultValue ? defaultValue.label : '',
		selectedValue: defaultValue ? defaultValue.value : '',
		inputValue: '',
		result: data,
		cursor: 0,
	});

	useEffect(() => {
		if (required) {
			const selectElement: HTMLSelectElement = document?.getElementById(inputId) as HTMLSelectElement;
			selectElement.selectedIndex = -1;
		}
		setSelectState((prevState) => {
			return {
				...prevState,
				result: data,
			};
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const { isActive, isFocused, displayLabel, selectedValue, inputValue, result } = selectState;

	const ref = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	useKeyPress('ArrowDown', (e) => {
		if (!isFocused) return;
		e.preventDefault();
		dispatch({ type: 'KEY_DOWN', data: result });
	});

	useKeyPress('ArrowUp', (e) => {
		if (!isFocused) return;
		e.preventDefault();
		dispatch({ type: 'KEY_UP', data: result });
	});

	useKeyPress('Enter', (e) => {
		if (!isFocused) return;
		e.preventDefault();
		if (isActive) {
			result.find((option, index) => {
				if (state.index === index) {
					onSelect?.(option.value);
					setSelectState((prevState) => {
						return {
							...prevState,
							displayLabel: option.label,
							selectedValue: option.value,
						};
					});
				}
				return;
			});
		}

		setSelectState((prevState) => {
			return {
				...prevState,
				isActive: false,
				isFocused: false,
				inputValue: '',
				result: data,
			};
		});

		dispatch({ type: 'CLEAR_INDEX' });
	});

	const clearSelection = (e: React.MouseEvent<HTMLSpanElement> | undefined) => {
		onClear?.();

		e?.preventDefault();
		e?.stopPropagation();

		inputRef.current?.focus();

		setSelectState((prevState) => {
			return {
				...prevState,
				isActive: true,
				isFocused: true,
				selectedValue: '',
				displayLabel: '',
				result: data,
			};
		});
	};

	const toggle = (e?: { preventDefault: () => void } | undefined) => {
		e?.preventDefault();
		return setSelectState((prevState) => ({ ...prevState, isActive: !isActive, isFocused: !isFocused }));
	};

	useOnClickOutside(ref, () => {
		if (isActive) {
			toggle();
		}

		if (!displayLabel) {
			setSelectState((prevState) => {
				return {
					...prevState,
					isFocused: false,
					inputValue: '',
					displayLabel: '',
				};
			});
		}

		if (displayLabel) {
			setSelectState((prevState) => {
				return {
					...prevState,
					inputValue: '',
					displayLabel: displayLabel,
				};
			});
		}

		dispatch({ type: 'CLEAR_INDEX' });
		setSelectState((prevState) => {
			return {
				...prevState,
				result: data,
				displayLabel: displayLabel ? displayLabel : '',
				selectedValue: selectedValue === inputValue ? selectedValue : '',
				cursor: 0,
			};
		});
	});

	const onSelectOption = (dataObject: SelectOption, e?: any) => {
		e?.preventDefault();
		onSelect?.(dataObject?.value);

		const selectElement: HTMLSelectElement = document?.getElementById(inputId) as HTMLSelectElement;
		const selectedIndex = e.currentTarget.getAttribute('data-index');
		selectElement.selectedIndex = selectedIndex;

		setSelectState((prevState) => {
			return {
				...prevState,
				isActive: false,
				isFocused: false,
				selectedValue: dataObject?.value,
				displayLabel: dataObject?.label,
				inputValue: '',
				result: data,
			};
		});
	};

	const isControlled = selected !== undefined;

	if (isControlled && selectState.selectedValue !== selected?.value) {
		setSelectState((prevState) => ({
			...prevState,
			selectedValue: selected?.value,
			displayLabel: selected?.label,
		}));
	}

	const handleFocus = () => {
		setSelectState((prevState) => {
			return {
				...prevState,
				isFocused: true,
			};
		});
	};

	const handleBlur = () => {
		if (required && selectState.selectedValue === '') alert('Please select an option');
		setSelectState((prevState) => {
			return {
				...prevState,
				isFocused: false,
			};
		});
	};

	const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		const suggestions = filterFunction(data, value);

		if (suggestions.length === 0) {
			setSelectState((prevState) => {
				return {
					...prevState,
					inputValue: '',
				};
			});
		}

		setSelectState((prevState) => {
			return {
				...prevState,
				result: suggestions,
				isActive: true,
				inputValue: value,
			};
		});
	};

	const fieldClassNames = cls(
		styles.field,
		isActive && styles.showingResult,
		disabled && styles.disabled,
		invalid && styles.invalid,
	);

	const style = {
		height: `calc(38px * ${data?.length > 4 ? 4 : data?.length}px)`,
		maxHeight: size === 'small' ? '152px' : '216px',
	};

	return (
		<div className={cls(styles.container, className, bordered && styles.bordered)} data-testid={testID} ref={ref}>
			<select
				name={name ?? slugifyName(title)}
				id={inputId}
				className={styles.hiddenSelect}
				test-id={testID}
				required={required}
			>
				{data.map((option) => (
					<option key={option.id} id={option.id} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
			<div
				role="combobox"
				aria-haspopup="listbox"
				aria-expanded={isActive}
				aria-controls={listboxId}
				className={fieldClassNames}
				onClick={disabled ? undefined : toggle}
				aria-label={ariaLabel}
			>
				<div className={styles.fieldGroup}>
					<div className={styles.labelWrapper}>
						<FieldLabel
							htmlFor={inputId}
							title={required ? `${title} *` : title}
							isActive={isActive || isFocused}
							isEmpty={!displayLabel || disabled}
							size={size}
						/>

						{!inputValue && !disabled && (
							<div className={cls(styles.displayLabel, { [styles.displayLabelSmall]: size === 'small' })}>
								{displayLabel}
							</div>
						)}

						{searchable && (
							<input
								type="text"
								role="searchbox"
								aria-autocomplete="list"
								autoComplete="off"
								ref={inputRef}
								id={inputId}
								autoFocus={autoFocus}
								className={cls(styles.input, { [styles.small]: size === 'small' })}
								onChange={handleChange}
								onFocus={handleFocus}
								onBlur={handleBlur}
								value={inputValue}
								aria-controls={inputId}
								disabled={disabled}
								name={name}
							/>
						)}
					</div>
					<SelectIcons
						hasValue={!!displayLabel}
						required={required}
						clearSelection={clearSelection}
						disabled={disabled}
						hideXMark={hideXMark}
					/>
				</div>
			</div>

			<div
				role="listbox"
				id={listboxId}
				aria-labelledby={labelId}
				className={cls(styles.listbox, { [styles.active]: isActive })}
				style={style}
			>
				{result?.map((option: SelectOption, index: number) => {
					return (
						<Option
							key={index}
							option={option}
							focused={state?.index === index}
							onSelect={(e) => onSelectOption(option, e)}
							isSelected={option.label === displayLabel}
							size={size}
							index={index}
						/>
					);
				})}
			</div>

			{helperText && !isActive && (
				<div className={cls(styles.helperText, { [styles.invalid]: invalid }, { [styles.disabled]: disabled })}>
					{helperText}
				</div>
			)}
		</div>
	);
};
