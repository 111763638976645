import { Heading, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { CardAttractStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';

interface BlokProps {
	blok: CardAttractStoryblok;
}

const blokProps = ({ blok }: BlokProps): CardAttractProps => ({
	title: blok.title,
	text: blok.text,
	linkButton: blok.linkButton?.[0] && LinkButton.blokProps({ blok: blok.linkButton[0] }),
	_uid: blok._uid,
	_editable: blok._editable,
});

export interface CardAttractProps {
	title: string;
	text?: string;
	linkButton?: LinkButtonProps;
	_uid?: string;
	_editable?: string;
}

export const CardAttract: Blok<CardAttractProps, BlokProps> = ({ title, text, linkButton, _editable }) => {
	return (
		<div {...editableBlok({ _editable })}>
			<Heading as="h3" size="h5" title={title} />
			<Text marginBottom={linkButton ? 'md' : 'none'}>{text}</Text>
			{linkButton && <LinkButton {...linkButton} variant="text" />}
		</div>
	);
};

CardAttract.blokProps = blokProps;
