import { IconButton } from '@/atoms';
import { LinkButton, buildLinkProps } from '@/bloks/Button';
import { SubMenuGroupStoryblok, SubMenuItemStoryblok, SubMenuV2Storyblok } from '@/components';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { useSearch } from '@/contexts/search';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import styles from './SubMenuDesktop.module.scss';

interface Props {
	items: SubMenuV2Storyblok['items'];
	hideGlobalMenu?: boolean;
	activeItem?: SubMenuGroupStoryblok | SubMenuItemStoryblok | null;
	className?: string;
}

export const SubMenuDesktop: React.FC<Props> = ({ items, hideGlobalMenu, activeItem, className }) => {
	const { showFirstPanel, isMenuOpen, menuVersionV3 } = useGlobalMenu();
	const { open } = useSearch();
	const [isTopPosition, setIsTopPosition] = useState(false);
	const [openSubGroupId, setOpenSubGroupId] = useState<string>('');
	const [zIndexClass, setZIndexClass] = useState(false);
	const themeInfo = getThemeInfo('submenuV2');
	const subMenuRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (subMenuRef.current) {
				const topZero = subMenuRef.current.getBoundingClientRect().top === 0;
				setIsTopPosition(topZero);
			}
		};

		window.addEventListener('scroll', handleScroll);
		handleScroll();

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		let timer: NodeJS.Timeout;

		if (showFirstPanel || open || isMenuOpen) {
			setZIndexClass(true);
		} else {
			timer = setTimeout(() => {
				setZIndexClass(false);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [showFirstPanel, open, isMenuOpen]);

	const toggleSubGroup = (_uid: string) => {
		if (openSubGroupId === _uid) {
			setOpenSubGroupId('');
			return;
		}

		setOpenSubGroupId(_uid);
	};

	return (
		<div
			className={cls(
				styles.container,
				hideGlobalMenu && styles.hideGlobalMenu,
				isTopPosition && styles.boxShadow,
				zIndexClass && styles.zIndex,
				menuVersionV3 && styles.menuVersionV3,
				className,
			)}
			ref={subMenuRef}
		>
			<nav className={styles.subMenuDesktop}>
				{items.map((item) => {
					const hasSubItems = !!item.items?.length;
					const ariaLabel =
						openSubGroupId === item._uid
							? `${t`Stäng undermeny för`} ${item.text}`
							: `${t`Öppna undermeny för`} ${item.text}`;

					return (
						<div key={item._uid} className={cls(styles.item, openSubGroupId === item._uid && styles.openLinkGroup)}>
							<div className={cls(styles.mainItemWrapper, hasSubItems && styles.hasSubItems)}>
								<LinkButton
									href={buildLinkProps(item.link).href}
									variant="link"
									className={cls(activeItem?._uid === item._uid && styles.active)}
								>
									{item.text}
								</LinkButton>
								{hasSubItems && (
									<IconButton
										iconName="chevron-down"
										className={cls(styles.icon, themeInfo.styles.textLink)}
										aria-label={ariaLabel}
										onClick={() => toggleSubGroup(item._uid)}
										iconSize="xs"
										aria-expanded={openSubGroupId === item._uid}
									/>
								)}
							</div>

							{hasSubItems && (
								<div className={styles.linkGroup}>
									{item.items?.map((subItem) => (
										<LinkButton
											key={subItem._uid}
											href={buildLinkProps(subItem.link).href}
											variant="link"
											className={cls(styles.subItem, activeItem?._uid === subItem._uid && styles.active)}
										>
											{subItem.text}
										</LinkButton>
									))}
								</div>
							)}
						</div>
					);
				})}
			</nav>
		</div>
	);
};
