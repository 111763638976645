import { Heading, Icon, Text } from '@/atoms';
import { IconPlus } from '@/atoms/IconPlus/IconPlus';
import { Blok } from '@/bloks/Blok';
import { UspSmallStoryblok } from '@/components';
import cls from 'classnames';
import { IconPlusName } from 'icons';
import { IconName } from '../../../icons/types';
import styles from './UspSmall.module.scss';

interface BlokProps {
	blok: UspSmallStoryblok;
	meta?: {
		layout?: UspSmallProps['layout'];
	};
}

const blokProps = ({ blok, meta }: BlokProps) => ({
	title: blok.title,
	iconName: blok.icon?.[0]?.name,
	iconPlus: blok.icon?.[0]?.component === 'IconPlus',
	layout: meta?.layout,
	text: blok.text,
	_uid: blok._uid,
});

export interface UspSmallProps {
	title?: string;
	iconName?: IconName;
	iconPlus?: boolean;
	layout?: 'center' | 'left';
	text?: string;
	showText?: boolean;
	_uid: string;
}

/**
 * Small usp with only text and icon. Used in Testimonial component and SectionUsp.
 * https://www.figma.com/file/7TGBESxdjHFWJFwKHN1Ip2/Webbplats-2021?node-id=158%3A6693
 */
export const UspSmall: Blok<UspSmallProps, BlokProps> = ({
	title,
	iconName,
	iconPlus,
	layout = 'left',
	text,
	showText = false,
}) => {
	const iconSize = layout === 'center' ? '6x' : '2x';
	const plusIconSize = layout === 'center' ? 'lg' : 'sm';
	const titleMargin = showText && text ? 'md' : 'none';
	return (
		<div className={cls(styles.container, styles[`layout--${layout}`])}>
			{iconName &&
				(iconPlus ? (
					<IconPlus name={iconName as IconPlusName} size={plusIconSize} className={styles.icon} />
				) : (
					<Icon name={iconName} size={iconSize} className={styles.icon} />
				))}
			{title && <Heading as="div" size="h6" title={title} marginBottom={titleMargin} />}
			{showText && text && <Text className={styles.text}>{text}</Text>}
		</div>
	);
};

UspSmall.blokProps = blokProps;
