import { Button, Text } from '@/atoms';
import { cls, truncateButWholeWords } from '@/utils';
import React from 'react';
import { t } from 'ttag';
import styles from './ExpandableDescription.module.scss';

interface Props {
	text?: string;
	maxCharacters: number;
	maxParagraphs: number;
	expanded: boolean;
	setExpanded: (value: boolean) => void;
}

export const ExpandableDescription: React.FC<Props> = ({
	text,
	maxCharacters,
	maxParagraphs,
	expanded,
	setExpanded,
}) => {
	let totalCharacters = 0;
	const textSections = text ? text?.trim().split('\n') : [];
	const showMoreButton = (
		<Button onClick={() => setExpanded(true)} variant="text">
			{t`Visa mer`}
		</Button>
	);

	if (expanded) {
		return (
			<div className={cls(styles.descriptionContainer)}>
				{text &&
					textSections?.map((currentRow, index) => {
						return (
							<Text key={index} marginBottom="none">
								{currentRow}
							</Text>
						);
					})}
				<Button onClick={() => setExpanded(false)} variant="text" className={styles.showLessButton}>
					{t`Visa mindre`}
				</Button>
			</div>
		);
	}

	return (
		<div className={cls(styles.descriptionContainer)}>
			{text &&
				textSections?.map((currentRow, index) => {
					const previousTotalCharacters = totalCharacters;
					totalCharacters += currentRow.length;
					if (previousTotalCharacters > maxCharacters || index > maxParagraphs) {
						return null;
					}

					if (index >= maxParagraphs) {
						return showMoreButton;
					}

					if (previousTotalCharacters + currentRow.length > maxCharacters) {
						return (
							<Text key={index} className={cls(styles.description)} marginBottom="none">
								{truncateButWholeWords(currentRow, maxCharacters - previousTotalCharacters) + '... '}
								{showMoreButton}
							</Text>
						);
					}
					return (
						<Text key={index} className={styles.description} marginBottom="none">
							{currentRow}
						</Text>
					);
				})}
		</div>
	);
};
