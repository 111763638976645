import { Grid } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button/LinkButton';
import { LinkToggleStoryblok } from '@/components';
import { useAnimationContext } from '@/contexts/Animations';
import { useImpressionTracking } from '@/contexts/piwik/ImpressionTrackingProvider';
import { useContrast, useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { usePathname } from 'next/navigation';
import styles from './LinkToggle.module.scss';

interface BlokProps {
	blok: LinkToggleStoryblok;
	addContrast?: boolean;
}

const blokProps = ({ blok }: BlokProps): LinkToggleProps => ({
	linkButtons: blok.linkButtons.map((linkButton) => LinkButton.blokProps({ blok: linkButton })),
	_editable: blok._editable,
});

export interface LinkToggleProps {
	linkButtons?: LinkButtonProps[];
	addContrast?: boolean;
	_editable?: string;
}

function useGridColumns(items: number) {
	if (items === 2) {
		return { base: 1, md: items };
	}
	return { base: 1, lg: items };
}

export const LinkToggle: Blok<LinkToggleProps, BlokProps> = ({ linkButtons, addContrast, _editable }) => {
	const theme = useTheme();
	const contrastTheme = useContrast();

	const themeInfo = addContrast && contrastTheme ? contrastTheme : theme;
	const { setTriggerAnimation } = useAnimationContext();
	const { setTargetInteraction } = useImpressionTracking();
	const pathname = usePathname();

	return (
		<div {...editableBlok({ _editable })} className={cls(styles.linkToggle, themeInfo?.styles?.linkToggleBgColor)}>
			<Grid columns={useGridColumns(linkButtons?.length ?? 1)} colGap="lg" rowGap="xs">
				{linkButtons?.map((linkButton, index) => {
					const activeLink = pathname === linkButton.href;

					return (
						<Grid.Item key={`link-button-${index}`}>
							<LinkButton
								block
								href={linkButton.href}
								className={cls(
									activeLink && styles.disableHoverEffect,
									!activeLink && themeInfo.styles.linkToggleInactive,
									!activeLink && styles.inactiveLink,
									activeLink && themeInfo.styles.linkToggleActive,
								)}
								onClick={() => {
									setTriggerAnimation(true);
									setTargetInteraction({ contentTarget: linkButton.children as string });
								}}
							>
								{linkButton.children}
							</LinkButton>
						</Grid.Item>
					);
				})}
			</Grid>
		</div>
	);
};
LinkToggle.blokProps = blokProps;
