import { BrandLine, Text } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { ImageCaptionStoryblok } from '@/components';
import { editableBlok } from '@/utils/storyblok';

interface BlokProps {
	blok: ImageCaptionStoryblok;
}

const blokProps = ({ blok }: BlokProps): ImageCaptionProps => ({
	text1: blok.text1,
	text2: blok.text2,
	_editable: blok._editable,
});

export interface ImageCaptionProps {
	text1: string;
	text2?: string;
	_editable?: string;
}

export const ImageCaption: Blok<ImageCaptionProps, BlokProps> = ({ text1, text2, _editable }) => {
	return (
		<div {...editableBlok({ _editable })}>
			<BrandLine addContrast />
			<Text marginBottom="none" addContrast>
				<strong>{text1}</strong>
			</Text>
			<Text addContrast>{text2}</Text>
		</div>
	);
};

ImageCaption.blokProps = blokProps;
